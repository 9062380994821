const AboutMeText = `
    Hi! 
    I'm a new media artist, creative technologist, and helicopter cat mom based in Brooklyn, NY. 
`

export {AboutMeText}

/*

Hi! I'm a multimedia artist / creative technologist / cat mom based in Brooklyn, NY. I enjoy working experimenting with new technologies and mixing different mediums. I am open to collaborations!


*/