const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}

const CriticalObjectsPosts = [
    {
        name: 'Week 1: Disobedient Device Proposal',
        date: 'February 3, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                “The absence of the palpebral fold produces a passive expression which 
                seems to epitomize the stoical and unemotional manner of the Oriental,” 
                wrote Dr. D Ralph Millard who invented and popularized double eyelid surgery, 
                a procedure that is now one of the most commonly performed plastic surgeries in Asia and 
                the third most popular cosmetic procedure in the world. 
                White businessmen preferred to conduct business with Asians with double eyelids, since they considered the monolid and flat nose as characteristics that hinted laziness and distrust and thus, white doctors considered procedures such as the double eyelid surgery a necessity. 

                
                <i>Eyes Wide Cut</i> gives Dr. Millard exactly what he wanted: an Asian with a thinner nose, double eyelids, and a permanent smile.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    As Sid and I were discussing different interventions for this issue, we decided to keep the project simple without an Arduino. 
                    In developing this project, we have many aesthetic inspirations ranging from products that already exist to address these cosmetic concerns to medieval torture devices used on accused witches.
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/co_clockworkorange.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/co_nosehuggie.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/co_scoldsbridle.jpg'
            }
        ]
    },
    {
        name: 'Midterm Proposal',
        date: 'March 2, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/hert_default-01.jpg'
            },

            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `HERT`
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `As a female software engineer, I suffer from perpetual self-doubt in a predominantly male-dominated work environment. Logically, I know that I am good at coding but lack confidence which permeates into my everyday vernacular. I tack on extra punctuation marks and smiley faces to make sure that the receiver doesn’t think I’m angry :) :D! I follow assertions that I know are true with “I don’t know” and introduce questions with “This might be a dumb question but…”.
                `
            },

            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                This pressure to speak in undermining speech patterns is engrained in everyone who is excluded from a white, heterosexual, patriarchal world, though I will be focusing specifically on the conditioned vocabulary of female-identifying individuals. I can’t change the fact that we live in a male-dominated world that pressures girls at a young age to be polite and unassertive. However, I can bring awareness to these engrained speech patterns and condition myself out of them.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                HERT, the wearable back brace, will prevent you from ever saying “sorry” or “I don’t know” ever again. With spikes along the spine, HERT constricts, bringing painful awareness to your denigrating speech patterns and forcing you to straighten your backbone. So choose your words wisely because she’s always listening! Playing into the torture aspect of the device, the brace is fashioned from leather decorated with spikes on the inside and outside. At the end of the day, she will also send you an email summarizing what you need to work on.                `
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                Phrases/words were chosen from Tara Mohr’s 
                    <a href="https://www.taramohr.com/communication-tips/8-ways-women-undermine-themselves-with-their-words/">“8 Ways Women Undermine Themselves with Their Words”</a>, 
                    the <a href="https://chrome.google.com/webstore/detail/just-not-sorry-the-gmail/fmegmibednnlgojepmidhlhpjbppmlci?hl=en-US">Just Not Sorry Google Chrome plugin<a/>, and from personal experience. Triggers include: “Sorry”, “just”, “Did that make sense?”, “dumb”, “I’m no expert…”, “I think”, “I feel like…”, “I’m not very good at this”, though this list can be customized and expanded depending on the user. When the device hears a trigger phrase, it will constrict immediately for five seconds.`        
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/hert_sketches1.jpg'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                    Technology
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Instead of using Raspberry Pi’s built-in microphone which was low-quality and omnidirectional, audio is captured from a lavalier microphone for a hands-free, localized experience. Other peoples’ speech is still occasionally captured, though this occurs less frequently than with Raspberry Pi’s mic. Audio is processed in a Python application running on the Raspberry Pi 4 powered by an external battery, allowing for a portable device untethered to a laptop or phone. After experimenting with Google Speech-to-Text, I opted to use Watson for the audio processing, since it uses sockets to instantly transcribe conversation. A servo motor controls the constriction of the back brace.                `
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/hert_sketches3.jpg'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `Ideation`
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `In the context of Critical Objects, the topic I am addressing is female’s and other minority’s pressure to use undermining speech patterns with interactive as an attribute, constriction as a device, and frustration as the mood.`
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `Inspirations`
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                Lauren Lee McCarthy's <a href="https://lauren-mccarthy.com/Tools-for-Improved-Social-Interacting">Tools for Improved Social Interacting</a><br />

                Def Method's <a href="https://chrome.google.com/webstore/detail/just-not-sorry-the-gmail/fmegmibednnlgojepmidhlhpjbppmlci?hl=en-US">Just Not Sorry</a> Google Chrome plugin<br />

                Matt Kenyon's <a href="http://www.swamp.nu/projects/ied/">Improvised Empathetic Device</a><br />

                <a href="https://pavlok.com/">Pavlok</a><br />
                `
            },
        ]
    },
  
    {
        name: 'Midterm: Not Sorry',
        date: 'March 2, 2020',
        content: [
            {
                type: DataTypes.Iframe,
                iframe: `<iframe src="https://player.vimeo.com/video/402048970" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                <p><a href="https://vimeo.com/402048970">NotSorry</a> from <a href="https://vimeo.com/user30956853">sue</a> on <a href="https://vimeo.com">Vimeo</a>.</p>`,
                class: 'iframe'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `Not Sorry`
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/notsorry.jpg'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `As a female software engineer, I suffer from perpetual self-doubt in a predominantly male-dominated work environment. Logically, I know that I am good at coding but lack confidence which permeates into my everyday vernacular. I tack on extra punctuation marks and smiley faces to make sure that the receiver doesn’t think I’m angry :) :D! I follow assertions that I know are true with “I don’t know” and introduce questions with “This might be a dumb question but…”.
                `
            },

            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                This pressure to speak in undermining speech patterns is engrained in everyone who is excluded from a white, heterosexual, patriarchal world, though I will be focusing specifically on the conditioned vocabulary of female-identifying individuals. I can’t change the fact that we live in a male-dominated world that pressures girls at a young age to be polite and unassertive. However, I can bring awareness to these engrained speech patterns and condition myself out of them.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Not Sorry is a harness/choker that will prevent you from ever saying “sorry” or “I don’t know” ever again. With spikes along the spine and throat, Not Sorry constricts, bringing painful awareness to your denigrating speech patterns. So choose your words wisely because she’s always listening! `
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                Phrases/words were chosen from Tara Mohr’s 
                    <a href="https://www.taramohr.com/communication-tips/8-ways-women-undermine-themselves-with-their-words/">“8 Ways Women Undermine Themselves with Their Words”</a>, 
                    the <a href="https://chrome.google.com/webstore/detail/just-not-sorry-the-gmail/fmegmibednnlgojepmidhlhpjbppmlci?hl=en-US">Just Not Sorry Google Chrome plugin<a/>, and from personal experience. Triggers include: “Sorry”, “just”, “Did that make sense?”, “dumb”, “I’m no expert…”, “I think”, “I feel like…”, “I’m not very good at this”, though this list can be customized and expanded depending on the user. When the device hears a trigger phrase, it will constrict immediately for five seconds.`        
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                    Technology
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Instead of using Raspberry Pi’s built-in microphone which was low-quality and omnidirectional, audio is captured from a lavalier microphone for a hands-free, localized experience. Other peoples’ speech is still occasionally captured, though this occurs less frequently than with Raspberry Pi’s mic. Audio is processed in a Python application running on the Raspberry Pi 4 powered by an external battery, allowing for a portable device untethered to a laptop or phone. After experimenting with Google Speech-to-Text, I opted to use Watson for the audio processing, since it uses sockets to instantly transcribe conversation. A servo motor controls the constriction of the choker.                `
            },

            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `Ideation`
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `In the context of Critical Objects, the topic I am addressing is female’s and other minority’s pressure to use undermining speech patterns with interactive as an attribute, constriction as a device, and fear as the mood.`
            },

            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                <a href="https://github.com/wiseshrimp/hert_">Code</a>
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `Inspirations`
            },
            
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                Lauren Lee McCarthy's <a href="https://lauren-mccarthy.com/Tools-for-Improved-Social-Interacting">Tools for Improved Social Interacting</a><br />

                Def Method's <a href="https://chrome.google.com/webstore/detail/just-not-sorry-the-gmail/fmegmibednnlgojepmidhlhpjbppmlci?hl=en-US">Just Not Sorry</a> Google Chrome plugin<br />

                Matt Kenyon's <a href="http://www.swamp.nu/projects/ied/">Improvised Empathetic Device</a><br />

                <a href="https://pavlok.com/">Pavlok</a><br />
                `
            },
        ]
    },
    {
        name: 'Final: Refusal',
        date: 'May 11, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi1.gif'
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                <i>Refusal</i> is an exploration of my Asian-American identity in an increasingly tumultuous time to be Asian in America since the outbreak of the COVID-19 pandemic. I create a vision of my parents' immigration to America and an idealized version of Korea in the form of pojagis, a traditional Korean textile method of quilt-making in which carefully folded seam methods produce no wrong or right side. Made of recycled linen and cheese cloth, two pojagis face each other separated but intertwined with string threaded through fleshy clay pieces located in the cheese cloth squares. The string is sewn together with slurs I've heard in America and Korea translated into Korean and English respectively, so only those who speak both languages can understand its context. Two motors tighten and loosen the strings, resulting in a push and pull dance.
                `
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'right',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi4.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'right',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi5.jpg'
            },
        ]
    }
]

export default CriticalObjectsPosts