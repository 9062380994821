import {AboutMeText} from './about'
import OneHundredDays from './100days'
import CriticalObjectsPosts from './criticalobjects'
import MathForArtistsPosts from './mathforartists'
import PerformativeAvatarBlogPosts from './performativeavatars'
import SyntheticArchitecturesPosts from './syntheticarch'
import {DiscomfortBlogPosts, ICMBlogPosts, FabBlogPosts, PCompBlogPosts} from './blog'
import {Projects} from './projects'

const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}

const DesktopIcons = [
    'about-me-btn',
    'work-btn',
    'blog-btn'
]

const MenuItems = {
    about: {
        name: 'About Me',
        text: AboutMeText
    },
    work: {
            virtual: {
                    CaryatidRebellion: Projects.CaryatidRebellion,
                    Susan: Projects.Susan,
                    ThatSoundYouHeard: Projects.ThatSoundYouHeard,
                    AlmostAmerican: Projects.AlmostAmerican,
                    Stillness: Projects.Stillness,
                    Drowning: Projects.Drowning,
                    SanBaudelio: Projects.SanBaudelio,
                    Entrypoint: Projects.Entrypoint,
                    LubeRiver: Projects.LubeRiver,
                    MuseumMe: Projects.MuseumMe
            },
            physical:
                {
                    InSearch: Projects.InSearch,
                    InvisibleWomen: Projects.InvisibleWomen,
                    NotSorry: Projects.NotSorry,
                    Freestanding: Projects.Freestanding,
                    WindsRocksWomen: Projects.WindsRocksWomen,
                    DanceOfDespair: Projects.DanceOfDespair,
                    InflatableHome: Projects.InflatableHome
                }
    },
    blog: {
        PERFORMATIVEAVATARS: {
            Week4: PerformativeAvatarBlogPosts[3],
            Week3: PerformativeAvatarBlogPosts[2],
            Week2: PerformativeAvatarBlogPosts[1],
            Week1: PerformativeAvatarBlogPosts[0]
        },
        '100DAYS': {
            Day100: OneHundredDays[99],
            Day99: OneHundredDays[98],
            Day98: OneHundredDays[97],
            Day97: OneHundredDays[96],
            Day96: OneHundredDays[95],
            Day95: OneHundredDays[94],
            Day94: OneHundredDays[93],
            Day93: OneHundredDays[92],
            Day92: OneHundredDays[91],
            Day91: OneHundredDays[90],
            Day90: OneHundredDays[89],
            Day89: OneHundredDays[88],
            Day88: OneHundredDays[87],
            Day87: OneHundredDays[86],
            Day86: OneHundredDays[85],
            Day85: OneHundredDays[84],
            Day84: OneHundredDays[83],
            Day83: OneHundredDays[82],
            Day82: OneHundredDays[81],
            Day81: OneHundredDays[80],
            Day80: OneHundredDays[79],
            Day79: OneHundredDays[78],
            Day78: OneHundredDays[77],
            Day77: OneHundredDays[76],
            Day76: OneHundredDays[75],
            Day75: OneHundredDays[74],
            Day74: OneHundredDays[73],
            Day73: OneHundredDays[72],
            Day72: OneHundredDays[71],
            Day71: OneHundredDays[70],
            Day70: OneHundredDays[69],
            Day69: OneHundredDays[68],
            Day68: OneHundredDays[67],
            Day67: OneHundredDays[66],
            Day66: OneHundredDays[65],
            Day65: OneHundredDays[64],
            Day64: OneHundredDays[63],
            Day63: OneHundredDays[62],
            Day62: OneHundredDays[61],
            Day61: OneHundredDays[60],
            Day60: OneHundredDays[59],
            Day59: OneHundredDays[58],
            Day58: OneHundredDays[57],
            Day57: OneHundredDays[56],
            Day56: OneHundredDays[55],
            Day55: OneHundredDays[54],
            Day54: OneHundredDays[53],
            Day53: OneHundredDays[52],
            Day52: OneHundredDays[51],
            Day51: OneHundredDays[50],
            Day50: OneHundredDays[49],
            Day49: OneHundredDays[48],
            Day48: OneHundredDays[47],
            Day47: OneHundredDays[46],
            Day46: OneHundredDays[45],
            Day45: OneHundredDays[44],
            Day44: OneHundredDays[43],
            Day43: OneHundredDays[42],
            Day42: OneHundredDays[41],
            Day41: OneHundredDays[40],
            Day40: OneHundredDays[39],
            Day39: OneHundredDays[38],
            Day38: OneHundredDays[37],
            Day37: OneHundredDays[36],
            Day36: OneHundredDays[35],
            Day35: OneHundredDays[34],
            Day34: OneHundredDays[33],
            Day33: OneHundredDays[32],
            Day32: OneHundredDays[31],
            Day31: OneHundredDays[30],
            Day30: OneHundredDays[29],
            Day29: OneHundredDays[28],
            Day28: OneHundredDays[27],
            Day27: OneHundredDays[26],
            Day26: OneHundredDays[25],
            Day25: OneHundredDays[24],
            Day24: OneHundredDays[23],
            Day23: OneHundredDays[22],
            Day22: OneHundredDays[21],
            Day21: OneHundredDays[20],
            Day20: OneHundredDays[19],
            Day19: OneHundredDays[18],
            Day18: OneHundredDays[17],
            Day17: OneHundredDays[16],
            Day16: OneHundredDays[15],
            Day15: OneHundredDays[14],
            Day14: OneHundredDays[13],
            Day13: OneHundredDays[12],
            Day12: OneHundredDays[11],
            Day11: OneHundredDays[10],
            Day10: OneHundredDays[9],
            Day9: OneHundredDays[8],
            Day8: OneHundredDays[7],
            Day7: OneHundredDays[6],
            Day6: OneHundredDays[5],
            Day5: OneHundredDays[4],
            Day4: OneHundredDays[3],
            Day3: OneHundredDays[2],
            Day2: OneHundredDays[1],
            Day1: OneHundredDays[0],
        },
        CRITICALOBJECTS: {
            Final: CriticalObjectsPosts[3],
            Midterm: CriticalObjectsPosts[2],
            MidtermProposal: CriticalObjectsPosts[1],
            DisobedientObject: CriticalObjectsPosts[0]
        },
        VIDEOSCULPTURE: {

        },
        SYNTHETIC: {
            Threshold: SyntheticArchitecturesPosts[0]
        },
        MATHFORARTISTS: {
            Counting: MathForArtistsPosts[0]
        },
        ICM: {
            Final: ICMBlogPosts[2],
            Class2: ICMBlogPosts[1],
            Class1: ICMBlogPosts[0]
        },
        UOD: {
            Final: DiscomfortBlogPosts[5],
            FinalProposal: DiscomfortBlogPosts[4],
            Monument: DiscomfortBlogPosts[3],
            Surveillance: DiscomfortBlogPosts[2],
            HERT: DiscomfortBlogPosts[1],
            TruthOrDare: DiscomfortBlogPosts[0]
        },
        FAB: {
            MotorMounting: FabBlogPosts[5],
            Materials: FabBlogPosts[4],
            Enclosures: FabBlogPosts[3],
            LaserCutter: FabBlogPosts[2],
            Making5: FabBlogPosts[1],
            Flashlight: FabBlogPosts[0],
        },
        PCOMP: {
            Week5: PCompBlogPosts[2],
            Week3: PCompBlogPosts[1],
            Week2: PCompBlogPosts[0],
        },
    },
}

const SocialIcons = [
    {
        class: 'email',
        link: 'mailto:sueroh@gmail.com'
    },
    {
        class: 'github',
        link: 'https://github.com/wiseshrimp'
    },

]


export {DesktopIcons, MenuItems, DataTypes, SocialIcons}