const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}

const OneHundredDays = [
    {
        name: 'Day 1',
        date: 'January 2, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day1_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day1_2.png'
            }
        ]
    },
    {
        name: 'Day 2',
        date: 'January 3, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Made a funky house with tweaks from a Youtube tutorial today. I can't wait until I learn how to texture!
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day2_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day2_2.png'
            },
        ]
    },
    {
        name: 'Day 3',
        date: 'January 4, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/kidrobot1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/kidrobot2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/kidrobot3.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/kidrobot4.png'
            }
        ]
    },
    {
        name: 'Day 4',
        date: 'January 5, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Mashed together two different models of a bird and a cat to make BirdCat
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day3.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day3_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day3_2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day3_3.png'
            },
        ]
    },
    {
        name: 'Day 5',
        date: 'January 6, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                This was the first model I made without watching a tutorial! A little mini model of my bedroom :P
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day4.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day4_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day4_2.png'
            },
        ]
    },
    {
        name: 'Day 6',
        date: 'January 7, 2020',
        content: [

            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Made a creepy hand today!
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day5.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day5_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Day5_2.png'
            },
        ]
    },
    {
        name: 'Day 7',
        date: 'January 8, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/littleguy.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/littleguy_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/littleguy_2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/littleguy_3.png'
            },
        ]
    },
    {
        name: 'Day 8',
        date: 'January 9, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tentacle.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tentacle2.png'
            }
        ]
    },
    {
        name: 'Day 9',
        date: 'January 10, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flowers1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flowers2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flowers3.png'
            }
        ]
    },
    {
        name: 'Day 10',
        date: 'January 11, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Made a lil axolotl today. 
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/axolotl.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/axolotl2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/axolotl3.png'
            }
        ]
    },

    {
        name: 'Day 11',
        date: 'January 12, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Part one of a character
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/GuyWithRobe.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/GuyWithRobe1.png'
            }
        ]
    },
    {
        name: 'Day 12',
        date: 'January 13, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Part two of a character! Added gloves, scarf, and boots :)
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Character2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Character2_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Character2_2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Character2_3.png'
            }
        ]
    },
    {
        name: 'Day 13',
        date: 'January 14, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Part three! Creepy head

                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Character3.png',
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/Character3_1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/character3_2.png'
            }
        ]
    },
    {
        name: 'Day 14',
        date: 'January 15, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cupcake1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cupcake2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cupcake3.png'
            }
        ]
    },
    {
        name: 'Day 15',
        date: 'January 16, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tree1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tree2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tree3.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tree4.png'
            }
        ]
    },
    {
        name: 'Day 16',
        date: 'January 17, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/mushroomfriends.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/mushroomfriends2.png'
            }
        ]
    },
    {
        name: 'Day 17',
        date: 'January 18, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                Modeled some modern houses including <a href="https://upload.wikimedia.org/wikipedia/en/3/3c/VillaSavoye.jpg">Villa Savoye</a>
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/villasavoye1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/villasavoye2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/villasavoye3.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/modernhouse.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/modernhouse2.png'
            }
        ]
    },
    {
        name: 'Day 18',
        date: 'January 19, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/wishingwell1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/wishingwell2.png'
            }
        ]
    },
    {
        name: 'Day 19',
        date: 'January 20, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    First time using the UV editor to make my own custom textures! 
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/noface1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/noface2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/noface3.png'
            }
        ]
    },
    {
        name: 'Day 20',
        date: 'January 21, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                First time using lights and rendering!
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/worm1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/worm2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/worm3.jpg'
            }
        ]
    },
    {
        name: 'Day 21',
        date: 'January 22, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    Wish I could play in this little guy's fur all day
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/furry1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/furry2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/furry3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/furry4.jpg'
            }
        ]
    },
    {
        name: 'Day 22',
        date: 'January 23, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bunny1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bunny2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bunny3.jpg'
            },
        ]
    },    {
        name: 'Day 23',
        date: 'January 24, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                Playing with neon lighting and 3D text.<br />Here are some of my essential identities.
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/infj.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/leo.jpg'
            }
        ]
    },
    {
        name: 'Day 24',
        date: 'January 25, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/alien1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/alien2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/alien3.jpg'
            }
        ]
    },
    {
        name: 'Day 25',
        date: 'January 26, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/friendwithflower.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/friendwithflower2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/friendwithflower3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/friendwithflower4.jpg'
            }
        ]
    },
    {
        name: 'Day 26',
        date: 'January 27, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sculptures.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sculptures2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sculptures3.jpg'
            }

        ]
    },
    {
        name: 'Day 27',
        date: 'January 28, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/forms1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/forms2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/forms3.jpg'
            }

        ]
    },
    {
        name: 'Day 28',
        date: 'January 29, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/muscleman1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/muscleman2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/muscleman3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/muscleman4.jpg'
            },
        ]
    },
    {
        name: 'Day 29',
        date: 'January 30, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/rat1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/rat2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/rat3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/rat4.jpg'
            },
        ]
    },
    {
        name: 'Day 30',
        date: 'January 31, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bjork1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bjork2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bjork3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bjork4.jpg'
            },
        ]
    },
    {
        name: 'Day 31',
        date: 'February 1, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cat1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cat2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cat3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cat4.jpg'
            },
        ]
    },
    {
        name: 'Day 32',
        date: 'February 2, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/merman1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/merman2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/merman3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/merman4.jpg'
            },
        ]
    },
    {
        name: 'Day 33',
        date: 'February 6, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingman1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingman2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingman3.jpg'
            },
        ]
    },
    {
        name: 'Day 34',
        date: 'February 7, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingoctopus1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingoctopus2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingoctopus3.jpg'
            }
        ]
    },
    {
        name: 'Day 35',
        date: 'February 8, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbuilding1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbuilding2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbuilding3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbuilding4.jpg'
            },
        ]
    },
    {
        name: 'Day 36',
        date: 'February 9, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/dinosaur1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/dinosaur2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/dinosaur3.jpg'
            }
        ]
    },
    {
        name: 'Day 37',
        date: 'February 10, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sharkarch1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sharkarch2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sharkarch3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sharkarch4.jpg'
            }
        ]
    },
    {
        name: 'Day 38',
        date: 'February 11, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/clamflower1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/clamflower2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/clamflower3.jpg'
            }
        ]
    },
    {
        name: 'Day 39',
        date: 'February 12, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingwithcylinder1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingwithcylinder2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingwithcylinder3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/buildingwithcylinder4.jpg'
            }
        ]
    },
    {
        name: 'Day 40',
        date: 'February 13, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/manshark1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/manshark2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/manshark3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/manshark4.jpg'
            }
        ]
    },
    {
        name: 'Day 41',
        date: 'February 14, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbuildingoctopus1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbuildingoctopus2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbuildingoctopus3.jpg'
            }
        ]
    },
    {
        name: 'Day 42',
        date: 'February 15, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/horsewithheels1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/horsewithheels2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/horsewithheels3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/horsewithheels4.jpg'
            }
        ]
    },
    {
        name: 'Day 43',
        date: 'February 16, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/treeballs1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/treeballs2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/treeballs3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/treeballs4.jpg'
            }
        ]
    },
    {
        name: 'Day 44',
        date: 'February 17, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/eyetable1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/eyetable2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/eyetable3.jpg'
            },
        ]
    },
    {
        name: 'Day 45',
        date: 'February 18, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/eyetabledisplay1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/eyetabledisplay2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/eyetabledisplay3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/eyetabledisplay4.jpg'
            }
        ]
    },
    {
        name: 'Day 46',
        date: 'February 19, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/crazypinkperson1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/crazypinkperson2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/crazypinkperson3.jpg'
            }
        ]
    },
    {
        name: 'Day 47',
        date: 'February 20, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/fishhookrose1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/fishhookrose2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/fishhookrose3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/fishhookrose4.jpg'
            }
        ]
    },
    {
        name: 'Day 48',
        date: 'February 21, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/plankton1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/plankton2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/plankton3.jpg'
            }
        ]
    },
    {
        name: 'Day 49',
        date: 'February 22, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/glassman.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/glassman2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/glassman3.jpg'
            }
        ]
    },
    {
        name: 'Day 50',
        date: 'February 23, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/whalethreeheads1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/whalethreeheads2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/whalethreeheads3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/whalethreeheads4.jpg'
            }
        ]
    },
    {
        name: 'Day 51',
        date: 'February 24, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computers1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computers2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computers3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computers4.jpg'
            }
        ]
    },
    {
        name: 'Day 52',
        date: 'February 25, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/firehydrant1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/firehydrant2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/firehydrant3.jpg'
            }
        ]
    },
    {
        name: 'Day 53',
        date: 'February 26, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/domestic_tranquility1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/domestic_tranquility2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/domestic_tranquility3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/domestic_tranquility4.jpg'
            }
        ]
    },
    {
        name: 'Day 54',
        date: 'February 27, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/building_corporate1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/building_corporate2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/building_corporate3.jpg'
            }
        ]
    },
    {
        name: 'Day 55',
        date: 'February 28, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/faces1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/faces2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/faces3.jpg'
            }
        ]
    },
    {
        name: 'Day 56',
        date: 'February 29, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cartree1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cartree2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cartree3.jpg'
            }
        ]
    },
    {
        name: 'Day 57',
        date: 'March 1, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Missing LA
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/freeways1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/freeways2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/freeways3.jpg'
            }
        ]
    },
    {
        name: 'Day 58',
        date: 'March 2, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tubularbeach1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tubularbeach2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tubularbeach3.jpg'
            }
        ]
    },
    {
        name: 'Day 59',
        date: 'March 3, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catfish1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catfish2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catfish3.jpg'
            }
        ]
    },
    {
        name: 'Day 60',
        date: 'March 4, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bananawall1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bananawall2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bananawall3.jpg'
            }
        ]
    },
    {
        name: 'Day 61',
        date: 'March 5, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/caryatid1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/caryatid2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/caryatid3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/caryatid4.jpg'
            }
        ]
    },
    {
        name: 'Day 62',
        date: 'March 6, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/potatogirl1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/potatogirl2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/potatogirl3.jpg'
            }
        ]
    },
    {
        name: 'Day 63',
        date: 'March 7, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computerheels1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computerheels2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computerheels3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/computerheels4.jpg'
            }
        ]
    },
    {
        name: 'Day 64',
        date: 'March 8, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desktears1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desktears2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desktears3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desktears4.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desktears5.jpg'
            }
        ]
    },
    {
        name: 'Day 65',
        date: 'March 9, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                ~Feeling scared~
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/facemasks1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/facemasks2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/facemasks3.jpg'
            },
        ]
    },
    {
        name: 'Day 66',
        date: 'March 10, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flowercorals1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flowercorals2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flowercorals3.jpg'
            },
        ]
    },
    {
        name: 'Day 67',
        date: 'March 11, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/falling1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/falling2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/falling3.jpg'
            },
        ]
    },
    {
        name: 'Day 68',
        date: 'March 12, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sausagegirl1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sausagegirl2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sausagegirl3.jpg'
            },
        ]
    },
    {
        name: 'Day 69',
        date: 'March 13, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/coolgirl1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/coolgirl2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/coolgirl3.jpg'
            },
        ]
    },
    {
        name: 'Day 70',
        date: 'March 14, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/queenoftoiletpaper1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/queenoftoiletpaper2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/queenoftoiletpaper3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/queenoftoiletpaper4.jpg'
            },
        ]
    },
    {
        name: 'Day 71',
        date: 'March 15, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/quarantine1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/quarantine2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/quarantine3.jpg'
            }
        ]
    },
    {
        name: 'Day 72',
        date: 'March 16, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/ropecars1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/ropecars2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/ropecars3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/ropecars4.jpg'
            }
        ]
    },
    {
        name: 'Day 73',
        date: 'March 17, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desert1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desert2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/desert3.jpg'
            }
        ]
    },
    {
        name: 'Day 74',
        date: 'March 18, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/socialdistancing1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/socialdistancing2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/socialdistancing3.jpg'
            }
        ]
    },

    {
        name: 'Day 75',
        date: 'March 19, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tvgirl1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tvgirl2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tvgirl3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tvgirl4.jpg'
            }
        ]
    },
    {
        name: 'Day 76',
        date: 'March 20, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/martini4.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/martini5.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/martini1.jpg'
            }
        ]
    },
    {
        name: 'Day 77',
        date: 'March 21, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Help me escape this cage!!!
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/nokia1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/nokia2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/nokia3.jpg'
            }
        ]
    },
    {
        name: 'Day 78',
        date: 'March 22, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hourglasses1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hourglasses2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hourglasses3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hourglasses4.jpg'
            }
        ]
    },

    {
        name: 'Day 79',
        date: 'March 23, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cylindricalofficebuilding.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cylindricalofficebuilding2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/goldofficebuilding.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hbuilding.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/pyramidbuilding.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/skyscraper1.jpg'
            }
        ]
    },
    {
        name: 'Day 80',
        date: 'March 24, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/boringbuilding.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/glassbuilding.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/sandwichbuilding.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/tetrisbuilding.jpg'
            },
        ]
    },
    {
        name: 'Day 81',
        date: 'March 25, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    I'm starting to learn how to do character animations in Maya! ~Some notes that I took today~
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/animationnotes1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/animationnotes2.jpg'
            },
        ]
    },
    {
        name: 'Day 82',
        date: 'March 26, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    First animation! She's a little scary :)
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/fat_woman.gif'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/fat_woman_start.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/fat_woman_end.jpg'
            },            {
                type: DataTypes.Iframe,
                iframe: `    <iframe src="https://player.vimeo.com/video/403092071" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                `,
                class: 'iframe'
            },
            
        ]
    },
    {
        name: 'Day 83',
        date: 'March 27, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                        I've been working on modeling an abandoned block in Midtown. Going to texture tomorrow :P
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cityblock1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cityblock2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cityblock3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/cityblock4.jpg'
            },
        ]
    },
    {
        name: 'Day 84',
        date: 'March 28, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Second animation ~ do the creep ~
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/small_boobs.gif'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/small_boobs_start.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/small_boobs_end.jpg'
            },            {
                type: DataTypes.Iframe,
                iframe: `<iframe src="https://player.vimeo.com/video/404112228" width="640" height="480" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
                class: 'iframe'
            },
            
        ]
    },
    {
        name: 'Day 85',
        date: 'March 29, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flat_chest.gif'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Third animation ~ do the creep ~
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flat_chest_start.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/flat_chest_end.jpg'
            },            {
                type: DataTypes.Iframe,
                iframe: `<iframe src="https://player.vimeo.com/video/404118365" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
                class: 'iframe'
            },
            
        ]
    },
    {
        name: 'Day 86',
        date: 'March 30, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    If I can't go outside, I'll make it!
                `
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/shenyun1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/shenyun2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/shenyun3.png'
            }

        ]
    },
    {
        name: 'Day 87',
        date: 'March 31, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/diner1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/diner2.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/diner3.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/diner4.png'
            }

        ]
    },
    {
        name: 'Day 88',
        date: 'April 1, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/origami1.gif'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/origami2.gif'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/origami3.gif'
            }
 
        ]
    },
    {
        name: 'Day 89',
        date: 'April 2, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/pret1.png'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/pret2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/pret3.jpg'
            }

        ]
    },
    {
        name: 'Day 90',
        date: 'April 3, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/purple_arms_1.gif'
            }

        ]
    },
    {
        name: 'Day 91',
        date: 'April 4, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/blue.gif'
            }

        ]
    },
    {
        name: 'Day 92',
        date: 'April 5, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/pink.gif'
            }

        ]
    },
    {
        name: 'Day 93',
        date: 'April 6, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/rope_1.gif'
            }

        ]
    },
    {
        name: 'Day 94',
        date: 'April 7, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/creature.gif'
            }

        ]
    },
    {
        name: 'Day 95',
        date: 'April 8, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/origamigirl1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/origamigirl2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/origamigirl3.jpg'
            }


        ]
    },
    {
        name: 'Day 96',
        date: 'April 9, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/catbird.gif'
            }

        ]
    },
    {
        name: 'Day 97',
        date: 'April 10, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hotdog1.jpg'
            },
            
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hotdogs1.jpg'
            },
            
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/hotdogs2.jpg'
            }

        ]
    },
    {
        name: 'Day 98',
        date: 'April 11, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/shenyun_above1.jpg'
            },
            
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/shenyun_above2.jpg'
            }

        ]
    },
    {
        name: 'Day 99',
        date: 'April 12, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bagels1.jpg'
            },
            
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bagels2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bagels3.jpg'
            },
        ]
    },
    {
        name: 'Day 100',
        date: 'April 13, 2020',
        content: [

            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bibleperson1.jpg'
            },
            
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bibleperson2.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bibleperson3.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'half',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/100days/bibleperson4.jpg'
            }
        ]
    },
]

export default OneHundredDays