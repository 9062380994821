const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}

const SyntheticArchitecturesPosts = [
    {
        name: 'Week 1: Threshold Proposal',
        date: 'February 3, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    This week, we were assigned to devise two different worlds and create a portal connecting the two. Here are three proposals and (rough!) sketches for each.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    <b>Water Duality</b>: Earth is completely submerged in water and now, everything and everyone is an island.
                    Every object has two sides, one upright and another upside down and underwater. 
                    You can travel between the worlds by ducking under the water's surface and peeping at the underwater side of the world.
                `
            },
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/water-01.png'
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    <b>Apocalypse</b>: Technically, this is only one world separated by time. 
                    It begins with an idyllic nature scene with lush greenery, trees, a pond in the middle and buildings of a cityscape in the backdrop. 
                    Your physical movements (and speed of your movements) control the scene's progression of time. As you move, the idyllic scene slowly becomes wrecked with apocalyptic fires, acid rain, earthquake tremors, and other catastrophes. 
                    The gameplay was inspired by the VR game Superhot.
                `
            },
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/oasis1.jpg'
            },
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/oasis2.jpg'
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    <b>The Underworld</b>: Inspired by N.K. Jemisin's <i>Broken Earth</i> trilogy, I wanted to recreate the desolate, moonless, post-apocalyptic Earth that humans inhabit and the bubbling, magmic asthenosphere where the Stone Eaters reside.
                     You would move across worlds by lying down on the earth and dissolving underground.
                `
            },
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/lava-01.jpg'
            },
        ]
    }
]

export default SyntheticArchitecturesPosts