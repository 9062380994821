const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}

const CaryatidRebellion = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Final0446.png'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
        <div class="quote">"In a successful play the principal actors and actresses who contribute to its successes are given due praise... Not so with the artist's model. She remains ever anonymous. She is the tool with which the artist works, and none may do her obeisance, though she provides the inspiration for a masterpiece and is the direct cause of enriching the painter or sculptor” <br/>- Audrey Munson </div>
        
        <br />In New York City, there are only five monuments of named women but hundreds of anonymous ones such as caryatids, sculptures in the form of feminine bodies wearing elaborately draped garments in the place of columns, decorating government buildings, apartment brownstones, and department stores. Plaques publicize the names of the piece, often vague allegorical ideals such as Memory or Justice, and the sculptor, but the woman behind the sculpture remains hidden. The male monument is allowed individuality while her body is an allegory, unable to exist independently as flesh and blood. Looking for the original women behind the sculptures, I found Audrey Munson, Hettie Anderson, and Doris Doscher, three models who posed for many of the unnamed sculptures across the city. <br/><br/><a href="https://caryatid.place">The Caryatid Rebellion</a> is both an interactive comic and video experience with music by Violet June Ciavattone from Machine+ documenting the caryatid and sculptural revolution spearheaded by the Statue of Liberty. The experience begins with the caryatids’ current state, holding up buildings and institutions that continue to exclude and oppress, and documents their eventual rebellion and reclamation of the built world. Creating this world also involved my own process of rebellion by 3D modeling Korean architecture and designing avatars that looked more like me and other people of color, subverting the encoded white standards of beauty in Neoclassical architecture.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
        The Caryatid Rebellion comic can be found at <a href="https://caryatid.place">www.caryatid.place</a> and the full video experience can be seen below.
        `
    },
    {
        type: DataTypes.Iframe,
        class: 'iframe',
        iframe: `<iframe title="vimeo-player" src="https://player.vimeo.com/video/552081432?h=9d37c1a58e" width="640" height="360" frameborder="0" allowfullscreen></iframe>`
    },
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Final0692.png'
    },
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Final0901.png'
    },
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Final1745.png'
    },
    {
        type: DataTypes.Iframe,
        class: 'iframe',
        iframe: `<iframe src="https://player.vimeo.com/video/544792816?h=8b2ac1ce83" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
    }
]

const ThatSoundYouHeard = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/LexingtonStill.png'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
        Nestled into one of the curves of the frozen over Schoharie Creek, the Lexington House was deafeningly silent during our stay in the dead of winter except for the occasional rustling of a tarp curtain or the scuffling of a mysterious furry friend. Even though we were alone in a House that was intended to shelter hundreds, we felt the vestiges of former residents, vacationers, and artists matryoshkaed in the crumbling walls of the House.
        <br /><br />
        Made in collaboration with Jordan Walters and Claire Dickson, I wanted to capture the previous movement and activity of the House and its character through the personification of its essence. We researched the House’s history, took photos and videos, made measurements of walls and doorways, danced and sang to recreate its former life. Digging into the House’s archives, we discovered a pamphlet for a performance in the House by jazz musician Jeanne Lee called “Conspiracy.” Her visceral poetry and voice inspired our piece as we translated the House from a physical archive to a digital experience. We hope that you get a glimpse of the hallucinatory sanctuary that we experienced as you get lost in the different rooms and winding hallways.`
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'subtext',
        text: `Made during the Prattsville Art Center Residency`
    },
    {
        type: DataTypes.Iframe,
        class: 'iframe',
        iframe: '<iframe src="https://player.vimeo.com/video/532549002" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>'
    }
]

const Susan = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/internetgirl/susan2.png'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `<i>Susan</i> lives in your computer and pockets and she yearns for your attention constantly. Go visit her at <a href="https://www.susan.works/">susan.works</a>. 
        <i>Susan</i> is an 8-minute web based experience on Google Chrome inspired by the voice actresses behind Siri and Alexa, Susan Caplin and Susan Bennet.
        <br /><br />
        Exhibited at <a href="https://www.brownieproject.org/exhibitions/wildre-delivery-ndpk8">Brownie Project Shanghai</a> from June 11 to July 7, 2021.
        `
    },
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/DO01050055.JPG'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'img-caption',
        text: `Susan exhibited at Brownie Project
        `
    },
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/DO01050043.JPG'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'img-caption',
        text: `Susan exhibited at Brownie Project
        `
    },
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/internetgirl/susan_documentation.png'
    },
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/internetgirl/Susan1.png'
    },
    {
        type: DataTypes.Iframe,
        class: 'iframe',
        iframe: '<iframe src="https://player.vimeo.com/video/488769139" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
    }
]

const Stillness = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Stillness2.jpg'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `<i>Stillness</i> exploring a post-apocalyptic NYC through past mass advertising campaigns such as Shen Yun, Dan Smith Will Teach You Guitar, Dr. Zizmor, and many others. The animations are controlled by your movements, resulting in stillness when the user is not moving. 
        The landscape is populated with figures that you can enter to experience their internal world within the advertisement.`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2020
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            DEVELOPED USING UNREAL ENGINE AND HTC VIVE
        `
    },
    {
        type: DataTypes.Iframe,
        class: 'iframe',
        iframe: '<iframe src="https://player.vimeo.com/video/420141761" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
    },
]

const Drowning = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/DrowningMain.jpg'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `<i>Drowning</i> is a virtual reality experience of a world completely submerged in water and everything and everyone is an island. Every building, car, and person has two sides, one upright and upside down and underwater. You can travel between the two worlds by ducking under the water's surface.`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2020
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            DEVELOPED USING UNREAL ENGINE AND HTC VIVE
        `
    },
    {
        type: DataTypes.Image,
        class: 'left',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Drowning1.PNG'
    },
    {
        type: DataTypes.Image,
        class: 'right',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Drowning2.jpg'
    },
    {
        type: DataTypes.Image,
        class: 'left',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Drowning3.PNG'
    },
    {
        type: DataTypes.Image,
        class: 'right',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Drowning4.jpg'
    },
]


const Freestanding = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Freestanding2.jpg'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `In New York, only five of the 150 statues are of named women, but there are a plethora of unnamed ones including caryatids, stone columns in the shape of women serving as architectural support for buildings. <i>Freestanding</i> is an interactive projection mapping installation projecting footage of the various caryatids in windows across Brooklyn. Projections dynamically change as pedestrians walk closer to the projection, erasing her surroundings to unveil a freestanding sculpture.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2020
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            DEVELOPED USING JAVASCRIPT AND MADMAPPER
        `
    },
    {
        type: DataTypes.Image,
        class: 'left',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Freestanding1.jpg'
    },
    {
        type: DataTypes.Image,
        class: 'right',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/Freestanding3.jpg'
    },
]

const AlmostAmerican = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/AlmostAmerican.png'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `<i>Almost American</i> visualizes the magnitude of the rising number of hate crimes committed against Asian Americans since the COVID-19 outbreak through real-time stories reported to the Asian Pacific Policy and Planning Council. The simulation is populated with paper figures programmed with varying degrees of aggression walking along generated paths. When figures of different groups cross paths, the collision results in either a neutral or a negative encounter. One of the victim’s limbs falls and a story appears on the ground, which gradually fades away but leaves an imprint that permanently disrupts the landscape. The stories accumulate as the simulation runs until all of the figures are fully dismembered.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `Made in collaboration with <a href="https://www.katiewhan.com/">Katie Han</a>`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2020
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            DEVELOPED USING UNITY
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
            Below is part of the simulation, though the full simulation runs typically for about 1.5 to 2 hours.
        `
    },
    {
        type: DataTypes.Iframe,
        class: 'iframe',
        iframe: '<iframe src="https://player.vimeo.com/video/420942434" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
    },
]

const InSearch = [
    {
        type: DataTypes.Image,
        class: 'main-img',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi1.gif'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
        <i>In search of a place to call home</i> is an exploration of my Asian-American identity in an increasingly tumultuous time to be Asian in America since the outbreak of the COVID-19 pandemic. 
        Not feeling quite at home in America or Korea, I created a vision of the two countries a in the form of pojagis, a traditional Korean textile method of quilt-making in which carefully folded seam methods produce no wrong or right side. Made of recycled linen and cheese cloth, two pojagis face each other separated but intertwined with string threaded through fleshy clay pieces located in the cheese cloth squares. The string is sewn together with slurs I've heard in America and Korea translated into Korean and English respectively, so only those who speak both languages can understand its context. Two motors tighten and loosen the strings, resulting in a push and pull dance.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2020
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            Developed using Arduino and stepper motors
        `
    },
    {
        type: DataTypes.Image,
        class: 'left',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi2.jpg'
    },
    {
        type: DataTypes.Image,
        class: 'right',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi3.jpg'
    },
    {
        type: DataTypes.Image,
        class: 'left',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi4.jpg'
    },
    {
        type: DataTypes.Image,
        class: 'right',
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/pojagi5.jpg'
    },
]

const InvisibleWomen = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/InvisibleWomen.png',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: 'Cellophane is an interactive video installation that inserts the too frequently absent female figure in Hollywood, bringing viewers to question whose stories are told in movies and whose are left out. The installation, consisting of four hanging screens, reacts to the viewer using computer vision and infrared cameras, inserting the artist as she is covered in cellophane by invisible hands.'
    },
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/InvisibleWomen2.gif',
        class: 'main-img'
    },
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/InvisibleWomen.gif',
        class: 'main-img'
    },

    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `2020`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING OPENFRAMEWORKS
        `
    },
]

const Entrypoint = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/entrypoint_main.png',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'tagline',
        text: `
            Creating tools to make virtual reality easier to build & distribute.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
            I was hired in December 2016 to be Entrypoint’s first full stack engineer. 
            When I started, the product was just an idea. 
            Frustrated by the lack of good webVR content, we wanted to provide tools for artists with little technical knowledge to create immersive and interactive experiences. 
            Over a year, we turned this idea into a fully-realized 360/webVR drag-and-drop editing tool with an accompanying 360/webVR video player. 
            We raised $2 million in funding and had an official partnership with Snapchat and created custom webVR experiences for companies such as Viacom, Samsung, and Refinery29.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2016-2017
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            Made using Javascript, React/Redux, HTML5/CSS, WebGL, Three.js, Python, AWS, and Docker
        `
    },
    {
        type: DataTypes.Iframe,
        class: 'iframe',
        iframe: '<iframe src="https://player.vimeo.com/video/382129893" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
    },
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/entrypoint_editor.png',
        class: 'main-img'
    }
]

const SanBaudelio = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/sanbaudelio_main.png',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'tagline',
        text: `
            Preserving Historical Transformation: Augmented Reality at the San Baudelio de Berlanga Hermitage
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
        Until 1926, colorful wall paintings covered the interior of the San Baudelio de Berlanga Hermitage, making it a masterpiece of medieval art and architecture. However, due to spoliation, these iconographic paintings were removed from the walls and sold to museums around the world. Now the monument stands in a plundered and dismantled state, giving visitors an incomplete and impoverished experience of the site. Augmented Reality technology presents itself as the most viable and optimal preservation treatment for this monument. This non-invasive technique allows the monument to continue serving as a spiritual sanctuary, while documenting and preserving its transformation over time. Offering users such an immersive, interactive experience of the site blurs the line between the site’s present remains and its digital restoration. 
        `
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
            As the lead developer on this project with 
                <a href="https://www.soe.studio/">SOE Studio</a>,
            I helped create a cross-platform app that turned the Hermitage into a living document 
            of the historical deterioration that architectural spaces suffer over time with the goal
             of redefining preservation practices.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2018-2019
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING C++, UNITY, ARKIT, AND SWIFT
        `
    },
    {
        type: DataTypes.Iframe,
        iframe: '<iframe src="https://player.vimeo.com/video/314358492" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
        class: 'iframe'
    },
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/sanbaudelio_last.jpg',
        class: 'main-img'
    }
]

const LubeRiver = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/luberiver_main.jpg',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
            Lube River provides an intimate and playful environment to get acquainted with different sex toys and how they can be used as tools for exploring the infinite realm of pleasure for all gender identities. We hope to strengthen and expand users’ understanding of the diversity within the world of sex toys. Lube River is an interactive computer-generated virtual reality experience developed for the Oculus Rift.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
            Lube River is the first installment of Pillow Talk - a virtual reality series exploring topics excluded from traditional sex-education curriculum, such as gender and sexuality, consent, and masturbation. 
            As the sole engineer on this project spearheaded by <a href="https://www.motherlodelab.com/">Motherlode Lab</a>, I added interactivity to all of the sex toys and particle physics to the water and smoke.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2017-2018
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING UNREAL ENGINE, C++, AND HLSL SHADERS
        `
    },
    {
        type: DataTypes.Iframe,
        iframe: '<iframe src="https://player.vimeo.com/video/274773390" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><p><a href="https://vimeo.com/274773390">Lube River Premiere</a> from <a href="https://vimeo.com/user79506454">Motherlode Lab</a> on <a href="https://vimeo.com">Vimeo</a>.</p>',
        class: 'iframe'
    }
]

const MuseumMe = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/museumme_main.png',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
            Using photos and status from Facebook and Instagram, Museum Me offers a self-indulgent webVR experience, in which users can be museum-goers at their own museum.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2016
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING JAVASCRIPT, FACEBOOK API, INSTAGRAM API, THREE.JS
        `
    },
    {
        type: DataTypes.Iframe,
        isHTML: true,
        class: 'iframe',
        iframe: `<iframe src="https://www.youtube.com/embed/UnJnAv0ZKVc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
            I also did a tutorial on three.js:
        `
    },
    {
        type: DataTypes.Iframe,
        isHTML: true,
        class: 'iframe',
        iframe: `<iframe src="https://www.youtube.com/embed/ZBGX9C74WFE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    }
]

const InflatableHome = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/inflatablehome_main.jpg',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
            Wanting to experiment with different plastics, I made a ten-by-ten foot inflatable house using polyethylene sheeting with projections on all four sides of the space. 
            With sensors on the ground, the projection on the walls changed depending on the room of the house you were currently inhabiting.        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `2016`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING PROCESSING, KINECTPROJECTORTOOLKIT, ARDUINO, SENSORS, POLYETHYLENE SHEETING
        `
    },
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/inflatablehome_last.jpg',
        class: 'main-img'
    },
]

const NotSorry = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/notsorry.jpg',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        text: '<i>Not Sorry</i> is a harness that prevents the user from speaking in undermining speech patterns engrained in everyone who is excluded from a white. Heterosexual, patriarchal world. With spikes along the spine and throat, Not Sorry has a built-in microphone and performs real-time voice analysis to bring painful awareness to my self-doubting rhetoric.',
        class: 'maintext',
        isHTML: true
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            2020
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            Developed using Python and Raspberry Pi
        `
    },
    {
        type: DataTypes.Iframe,
        iframe: `<iframe src="https://player.vimeo.com/video/402048970" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <p><a href="https://vimeo.com/402048970">NotSorry</a> from <a href="https://vimeo.com/user30956853">sue</a> on <a href="https://vimeo.com">Vimeo</a>.</p>`,
        class: 'iframe'
    },
]

const CoolDog = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/cooldog_main.jpg',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
            Wanting to chill out while looking funky fresh, I made this dog mask out of repurposed steel. Inside of the dog’s nose is a battery-powered Arduino with a motion and temperature sensor that triggers a fan to turn on when I get toasty.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `2016`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING ARDUINO, SENSORS, JAVA, MIG WELDING
        `
    },
]

const DanceOfDespair = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/1O8A8567.jpg',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
            For our physical computation class, Katie Han and I worked together over the course of two weeks to create a mechatronic marionette that mirrors a user’s movements with a camera, controlling the marionette's joints with their physical movements and resulting in the convergence of the puppeteer and puppet. 
            The title of our piece, Dance of Despair, evokes the <a href="https://www.youtube.com/watch?time_continue=23&v=LU19Rqy1s9Y&feature=emb_title">dancing marionette scene</a> in <i>Being John Malkovich</i>.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext h2',
        text: `
            Technical Overview
        `
    },
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/1O8A8583.jpg',
        class: 'left'
    },
    {
        type: DataTypes.Text,
        isHTML: true,
        class: 'maintext',
        text: `
            Using the input captured from the computer’s camera, we used <a href="https://github.com/tensorflow/tfjs-models/tree/master/posenet">PoseNet</a>, 
            a machine learning library, to obtain real-time pose estimation in the browser. 
            Using p5.js, we overlaid a skeleton on top of the video capture input to visualize the user’s joint data. 
            In the spirit of Halloween, we show an image of a skull on the user’s face! 👻 
            We calibrated the skeleton data by having the user stand in a neutral position with all limbs visible to the camera. 
            After calibration, we calculated the angles of the joint positions as the user moved, which we then translated into relative angles for each of the motors to move. 
            The software portion of our project runs as a Node.js application that sends the motors’ angle information to Arduino Nano via serial communication.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: `
        Given the angle data for each of the six servo motors, the micro-controller parses and writes those values to the respective motors. We have six motors for six joints: left elbow, right elbow, left wrist, right wrist, left ankle, and right ankle. Each of the motors have a rod extending out from its horn and a string connecting the end of the rod to the corresponding joint. As a result, the movements of the servo motors can be translated to vertical movements of the limbs.
        `
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `2019`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING ARDUINO, JAVASCRIPT, & ML5
        `
    },
    {
        type: DataTypes.Iframe,
        isHTML: true,
        class: 'iframe',
        iframe: `
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/369225173" frameborder="0" allowfullscreen></iframe>
        `
    },
]

const WindRocksWomen = [
    {
        type: DataTypes.Image,
        src: 'https://sues-website.s3.us-east-2.amazonaws.com/windrockswomen_small2.png',
        class: 'main-img'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: 'Wind, Rocks, and Women is an interactive sound exhibit that tells the stories of two mothers in Korea: a mother in Seoul, where the traditional female role of domesticity prevails, and a haenyeo in Jeju Island, where women have greater freedom and status atypical of Korean society.'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'h2 maintext',
        text: 'Context'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: 'South Korea, despite being one of the largest economic powerhouses in the world, is consistently ranked as one of the highest countries in gender pay gaps in the OECD. With economic disadvantages undoubtedly come social implications of sexism that is deeply entrenched in all strata of Korean society. Particularly, women are expected to quit the workforce as full-time mothers due to societal pressures or sometimes outright discrimination by their employers. '
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: 'This is why we are particularly drawn to the haenyeo of Jeju Island who seem to defy the classic patriarchy that dominates mainland Korea. Haenyeo are divers in Jeju Island who submerge up to thirty meters underwater without the help of oxygen masks to gather abalone, octopus, and other delicious deep sea treasures. Though their occupation is dangerous, it is also lucrative, often designating them as breadwinners of their family. Their historical importance has contributed to Jeju’s characteristic semi-matriarchal attitude and the island’s three proud abundances (samda): wind, rocks, and women. They are the epitome of eco-feminism with their strong, tight-knit group of haenyeo who care for each other’s safety and wellbeing, while simultaneously ensuring the preservation of the ecosystem to which they owe their livelihood.'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'maintext',
        text: 'Our project serves as an homage to the haenyeo of Jeju Island, whose population is largely dwindling as expert haenyeo age and their daughters choose safer jobs on land. The installation is set in Jeju Island with the Hallasan volcano where gods and spirits oversee the island and the surrounding Korean Strait. Peppered in the water and on the volcano lie various objects narrating two parallel stories, one of haenyeo and another of a mother in Seoul.'
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `2019`
    },
    {
        type: DataTypes.Text,
        isHTML: false,
        class: 'subtext',
        text: `
            MADE USING ARDUINO, JAVASCRIPT, JAVA, PROCESSING
        `
    },
]

const Projects = {
    CaryatidRebellion: {
        name: 'The Caryatid Rebellion',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/CaryatidRebellion_Thumbnail.jpg',
        content: CaryatidRebellion
    },
    Susan: {
        name: 'Susan',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/internetgirl/susan2.png',
        content: Susan
    },
    ThatSoundYouHeard: {
        name: 'That Sound You Heard',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/Lexington_Thumbnail.jpg',
        content: ThatSoundYouHeard
    },
    Stillness: {
        name: 'Stillness',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/Stillness_Thumbnail.jpg',
        content: Stillness
    },
    AlmostAmerican: {
        name: 'Almost American',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/AlmostAmerican_Thumbnail.jpg',
        content: AlmostAmerican
    },
    Freestanding: {
        name: 'freestanding',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/Freestanding_Thumbnail.jpg',
        content: Freestanding
    },
    Drowning: {
        name: 'drowning',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/Drowning_Thumbnail.jpg',
        content: Drowning
    },
    InSearch: {
        name: 'in search of a place to call home',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/Pojagi_Thumbnail.jpg',
        content: InSearch
    },
    InvisibleWomen: {
        name: 'cellophane',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/InvisibleWomen_Thumbnail.jpg',
        content: InvisibleWomen
    },
    Entrypoint: {
        name: 'Entrypoint',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/Entrypoint_Thumbnail.jpg',
        content: Entrypoint
    },
    SanBaudelio: {
        name: 'San Baudelio',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/SanBaudelio_Thumbnail.jpg',
        content: SanBaudelio
    },
    LubeRiver: {
        name: 'Lube River',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/LubeRiver_Thumbnail.jpg',
        content: LubeRiver 
    },
    WindsRocksWomen: {
        name: 'wind, rocks, and women',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/WindRocksWomen_Thumbnail.jpg',
        content: WindRocksWomen
    },
    DanceOfDespair: {
        name: 'Dance of Despair',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/DanceofDespair_Thumbnail.jpg',
        content: DanceOfDespair
    },
    NotSorry: {
        name: 'Not Sorry',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/NotSorry_Thumbnail.jpg',
        content: NotSorry
    },
    Poetilde: {
        name: 'Poetilde: Chrome Extension',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/1O8A8583.jpg'
    },
    InflatableHome: {
        name: 'Inflatable Home',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/InflatableHome_Thumbnail.jpg',
        content: InflatableHome
    },
    CoolDog: {
        name: 'Cool Dog',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/cooldog_main.jpg',
        content: CoolDog
    },
    MuseumMe: {
        name: 'MuseumMe',
        thumbnail: 'https://sues-website.s3.us-east-2.amazonaws.com/MuseumMe_Thumbnail.jpg',
        content: MuseumMe
    }
}
export {Projects, DataTypes}