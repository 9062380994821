import React from 'react'
import { Link } from "react-router-dom"

export default class About extends React.Component {
    render() {
        return (
            <div className="about">
                <div className="slide-up-fade-in">
                    <div className="video-container">
                        {/* <video id="rotating_head" loop muted autoPlay preload>
                            <source src={process.env.PUBLIC_URL + "rotating_head_3.mp4"} type="video/mp4" />
                        </video> */}
                        <img 
                            id="rotating_head"
                            alt="3d model of my head rotating"
                            src="https://sues-website.s3.us-east-2.amazonaws.com/RotatingHead.gif" />
                    </div>
                    <div className="about-header">Hi!</div>
                    <div className="about-text">
                        I'm a new media artist, programmer, 
                        and helicopter cat mom living in Brooklyn, NY. 
                        I would love to help out on a future project. Please <a href="mailto:sueroh@gmail.com">drop a hello!</a>
                    </div>
                    <div className="about-text">
                        Thanks for visiting my pre-Y2K website. 
                    </div> 
                </div>
            </div>
        )
    }
}
