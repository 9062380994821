const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}


const PerformativeAvatarBlogPosts = [
    {
        name: 'Week 1: Creating Avatars',
        date: 'September 14, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                Sims 4
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/Sims.jpg',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                My friend got me an access code for my birthday so now I can finally play Sims 4! 
                I played Sims 3 and 2 back in the day, but I heard that Sims 4 was a massive upgrade in avatar creation. 
                Thankfully, the interface hasn't changed much. 
                I've always found it interesting that Sims displays preset faces, to which EA Games introduced a few Asian avatars as prefabs. 
                I was very pleased that they had an option to add a mole on my upper right cheek - I'm very attached to this facial feature! 
                For hair, I settled on a shaggier mullet with cat ears, which was perfect considering I was playing the Cats & Dogs expansion pack. 
                Other than the hair, Sims 4 offered customizable size and placement of all facial features, allowing me to round out my cheeks and flatten my nose a bit. Unfortunately, cat avatar options were breed elitist and I couldn't create an accurate avatar of my cats.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                Second Life
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/SecondLife.jpg',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                I played Second Life years ago but unfortunately, they haven't made the UI any better. It was a nightmare customizing my avatar, and clothing options were limited without buying from the marketplace. They didn't have my mole and my clothes didn't fully fit me after customizing my body features. I did manage to add a cat to my shoulder though! Despite Sims 4 being the user-friendlier option, I appreciate the extent to which Second Life allows for hair/eye color customization and flexibility with body proportions.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                Screengrabs
                `
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe width="560" height="315" src="https://www.youtube.com/embed/ov5m6cH85ZY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe width="560" height="315" src="https://www.youtube.com/embed/-w2-xaYN4Vo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        ]
    }, 
    {
        name: 'Week 2: Animating Avatars',
        date: 'September 23, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Initially, I wanted to animate an Adobe Fuse character with an attached computer head but ran into problems when re-rigging the skeleton in Maya. I managed to get the character into Mixamo, but it resulted in weird morphing in the computer mesh when animated.
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/TVPerson.png',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                So I opted to animate my face transfer character on Daz3D. I had some issues bringing the FBX file straight into Mixamo, so I attached a Mixamo skeleton onto the Daz3D mesh. I probably could do a better job in the future because there is some weird morphing in some of the animations. However, I did successfully get many versions of myself dancing in Unreal Engine! Crazy things happened when I turned off gravity and simulated physics.
                `
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe width="560" height="315" src="https://www.youtube.com/embed/gXODB5YPalk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        ]
    },
    {
        name: 'Week 3: More Animation Stuff',
        date: 'September 28, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                This week, I finally retargeted my Daz scan's skeleton correctly! For some reason, I lost the .fbx of the clothed me, so I only have a naked avatar running around.

<br /> <br />I had a good time working with animations/blend shapes. I thought the layered blends/transform bones tools were so much fun and incorporate an added degree of customization, making the Mixamo animations feel a little less out-of-the-box. 

<br/><br/>However, I was a bit confused how to use the blend shapes for other animations. After my avatar jumps, I wanted to blend two animations (falling down and getting back up) but was confused how to transition between the two without a velocity input?

                `
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe width="560" height="315" src="https://www.youtube.com/embed/DhW0WHqsJYs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        ]
    },
    {
        name: 'Week 4: My First Sequencer Video',
        date: 'October 5, 2020',
        content: [
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                This week, I had a lot of fun experimenting with textures for my avatar. I used Photoshop to customize my avatar's makeup/face and played around with animations on my avatar's face texture in After Effects to simulate crying and sparkles.
I also used the light function to simulate a projector light, which resulted in a not very realistic but ghostly projection on the ground and wall.
<br /><br />
This was my first time using Unreal's Sequencer tool and it was great, though I had a little difficulty matching up my avatar's animation in the scene with the camera.
                `
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe width="560" height="315" src="https://www.youtube.com/embed/YfcgUC21cx8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        ]
    }
]

export default PerformativeAvatarBlogPosts