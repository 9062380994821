import React from 'react'

export default class Clock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            time: '',
            isGlitching: false
        }
    }

    componentDidMount() {
        this.setTime()
        this.timeInterval = setInterval(this.setTime, 30000)
    }

    componentWillUnmount() {
        clearInterval(this.timeInterval)
    }

    checkTime(num) {
        if (num < 10) {num = "0" + num}  // add zero in front of numbers < 10
        return num
    }

    glitch = ev => {
        this.setState({
            isGlitching: !this.state.isGlitching
        })
    }

    setTime = () => {
        let today = new Date()
        let h = today.getHours()
        let m = today.getMinutes()
        m = this.checkTime(m)
        h = this.checkTime(h)
        h = h > 12 ? h - 12 : h
        h = h === '00' ? 12 : h
        this.setState({
            time: `${h}:${m}`
        })
    }
    
    render() {
        return (
            <div 
                id="clock"
                className={`${this.state.isGlitching ? 'upside-down': ''}`}
                onClick={this.glitch}>
                {this.state.time}
            </div>
        )
    }
}