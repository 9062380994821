const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}

const ClassTitles = {
    '100DAYS': '100 Days of Making',
    ICM: 'ICM: Media',
    UOD: 'Uses of Discomfort',
    PCOMP: 'Physical Computation',
    FAB: 'Intro to Fabrication',
    CRITICALOBJECTS: 'Critical Objects',
    SYNTHETIC: 'Synthetic Architectures',
    VIDEOSCULPTURE: 'Video Sculpture',
    MATHFORARTISTS: 'Math for Artists',
    PERFORMATIVEAVATARS: 'Performative Avatars'
}


const DiscomfortBlogPosts = [
    {
        name: 'Week 1: Truth or Dare',
        date: 'October 31, 2019',
        content: [
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                What makes truth or dare so uncomfortable is exposing your secrets and making a fool of yourself to others. You relinquish control for another person to decide your fate. As a kid playing truth or dare, I would get so embarrassed and typically lie to avoid humiliation, thereby defeating the purpose of the game. This week, my group members and I wanted to extract all of the discomfort out of truth or dare. Would it make players more comfortable and willing to share their truths? Would it change the types of questions and dares people asked knowing that they too would have to do them? If there is no discomfort in the game, is it still worth playing? 
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                We initially created a web-based truth or dare, which anonymized and randomized the entire experience. On the website, there were only three rules: 
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext indent',
                text: `
                1). There is a person who is “chosen” at random to ask a question or devise a dare. No one knows who is chosen.                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext indent',
                text: `
                2). If you are chosen, type what you want others to do or answer. `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext indent',
                text: `
                3). Everyone, including the chosen person, must answer the question or do the dare. Everyone’s answers are anonymously displayed on the screen.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                After the round is done, another person is randomly selected to be chosen. In the future, we thought it would be interesting to have an upload video function, so the game could be played virtually.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                However, it made little sense to use the website in a group of four people when we play-tested in class, so we slightly tweaked the rules. The only tweak was that the “chosen” person is randomly assigned and then the control goes counter-clockwise. We found through watching others play test and playing the game ourselves that it was still maintained some level of discomfort in the beginning, though the first few questions helped establish a rapport amongst players. Overall, we thought it was a great icebreaker and quick way to bond amongst strangers in a classroom :)
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/truthordare1.png',
                class: 'main-img'
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/truthordare2.png',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                    Reading Response
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Like Ida Benedetto, I have been thinking a lot about funerals and the necessity of collective mourning. Unfortunately over the past year, I’ve attended quite a few funerals. In September during the first week of ITP, my grandfather died. We had a traditional Jehovah’s Witness funeral which unlike other JW ceremonies was short and sweet. The entire procession was over within fifteen minutes. This short goodbye was quite the opposite from my ex-partner’s grandfather’s funeral which took place in February. His grandfather was Jewish, so we sat shiva for a week with different relatives and friends, while others visited and shared their condolences. Unlike my grandfather’s funeral, this mourning period was drawn out, exhausting, but meditative. My family is repressive of their emotions by nature, so our grieving process was experienced individually, while my ex-partner’s family grieved collectively.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                The purpose of these ceremonies are not only to say goodbye to a deceased loved one but also to mourn with others and consequently not feel so alone in grief. Are other people necessary, or do they make it a lot easier to achieve transformation in uncomfortable experiences? All of the rituals – sex parties, funerals, etc. – that Benedetto mentions are social experiences meant to be shared with other people. Would a funeral be as transformative if it were experienced alone?
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Traveling is enlightening and typically rooted in discomfort. Although people travel to beautiful places, we are leaving everything we know for the foreign. The thought of traveling alone, though I’ve done it before, is very uncomfortable for me, not only for safety reasons but also because of my need to process events and experiences through conversation. Movies and books for example have a greater impact on my life and worldview if I am able to sort through my emotions with someone else who has read or watched it too. However, I do need to be alone to individually process certain thoughts and form my own opinions. Other people can assuage discomfort or add another level of disturbance. However, when is individual discomfort without the presence of other people more conducive to a transformation? 
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                The purpose of these ceremonies are not only to say goodbye to a deceased loved one but also to mourn with others and consequently not feel so alone in grief. Are other people necessary, or do they make it a lot easier to achieve transformation in uncomfortable experiences? All of the rituals – sex parties, funerals, etc. – that Benedetto mentions are social experiences meant to be shared with other people. Would a funeral be as transformative if it were experienced alone?
                `
            },
        ]
    },
    {
        name: 'Week 2: HERT',
        date: 'November 7, 2019',
        content: [
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/hert2.png',
                class: 'left'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                As a female software engineer, I suffer from perpetual self-doubt in my predominantly male-dominated work environment. I double-check every single of my suggestions and opinions when my peers challenge me. I know logically that I am good at coding, but I lack the confidence, which permeates my everyday vocabulary. I add a lot of extra punctuation marks to my emails to make sure that people don’t think I’m angry :) ! I follow assertions that I know are true with “I don’t know”s. I introduce questions with: “This might be a dumb question, but…”, even though it likely is not a dumb question.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                I have an especially bad case of conditioned patriarchal vocabulary. This is not to say that this pattern of speech is exclusive to women – POCs and LGBTQ folks also face similar pressures in a white, heterosexual, patriarchal world – though I will be focusing primarily on women’s social pressures in this project. Girls from a young age are told to be polite and like-able. Women with power are highly scrutinized, while submissive women are safer. Thus, like-able women means self-doubting, questioning, and unassertive. It is especially frustrating because blame is put on adolescent girls who are told that they need to work on their confidence. However, I strongly disagree with this difference. If anything, I appreciate greatly how I talk to my similarly sensitive friends. We are willing to admit that we don’t know about certain things rather than assert our opinions as facts. to things that we don’t know about rather than stating them as fact. Peppering group discussions with an occasional “I don’t know” offers greater room for discussion.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                But unfortunately, I can’t change the fact that we live in a male-dominated world, to which I must conform my speech patterns. Thus this week, I fashioned a device called HERT, which functions similarly to Pavlok with a few tweaks. The main goal was to condition myself out of saying subordinating key words – “I don’t know”, “Sorry”, and “I feel like…” – through punishment.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                HERT: HER TRANSFORMATION
                `
            },
                        {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/hert1.png',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'subtext',
                text: `
                FABRICATION
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                The hardware for HERT was simple. I hooked up an Arduino with a servo and attached a needle/toothpick to the servo horn. The more complicated part was fashioning the wearable. If I had more time, I would like to have soldered the wires to the Nano so the resulting piece wasn’t as bulky and could have been hidden with a long sleeve shirt. I used an extra microfiber towel I had lying around and some leftover yellow cloth from my Halloween costume to sew together an arm strap to enclose the hardware. The sleeve was secured with Velcro.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'subtext',
                text: `
                PROGRAMMING
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/IMG_1223.gif',
                class: 'right'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                I created a web app that used the Web Audio API to analyze my speech in real time. I was really surprised by the accuracy of the transcriptions! All of the code can be found here. The program searched for three key phrases that I wanted to omit: “Sorry”, “I don’t know”, and “I feel like…”. If it found these key phrases in any part of my sentence, it would send a serial communication to the Arduino to trigger a prick.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Because I have not yet figured out how to control an Arduino from my iPhone, I had to be attached to a computer to do all of the audio processing. Thus, I was only able to wear my HERT during my part-time job and in select classes that allowed computers. Over approximately five days, I wore it about fifteen hours. However, I would have loved to create a phone app, so I could remote control the Arduino without the computer. 
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                Takeaways
                `
            },    
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                If I were to redo this project, I would definitely want to make an app for my iPhone, which would do the audio processing and serial communicate with my Arduino. Needing the computer to do the audio processing severely limited the time frames in which I was able to use the device. Also, as I mentioned before, I would love to transition this project to an Arduino Nano with a PCB to make the wearable smaller.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Lastly, I think it would be interesting to make a second device for men that requires them to use one of several key phrases (“sorry”, “I don’t know”, “please”, etc.) in every single one of their sentences to condition them to check their ego :)
                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                Reading Response
                `
            },  
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                One of my friends is particularly addicted to watching Youtube morning routine rituals, which I find an inexplicable waste of time not to mention boring as hell. I already hate the mornings – why would I watch someone else going through their morning? But this is precisely the reason why she watches them, she explained to me. Watching someone else go through their morning – waking up at 4:30am, doing yoga and exercise, writing in a journal, responding to emails – gives her a comparable level satisfaction as if she’s done those activities herself, a phenomena described by mirror neurons. I experience this in different ways. I’ve spent countless hours watching videos of ceramicists crafting large vases and teeny-tiny bowls that I will never make. I rationalize this behavior by explaining that it’s ‘educational’, though I yield little practical knowledge from passive observing. I still feel like I’m doing something even if I’m sitting by my computer eating ice cream.
                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                I think that a similar type of neural mechanism takes place when we watch others undergoing an emotionally or physically demanding experience. It’s part of our innate ability to empathize. When I see someone itch or yawn, I think that I also have to itch or yawn, and watching Stelarc’s Propel in class evoked strong reactions from all of us. It’s not simply visceral experiences that elicit a similar physical response but also watching emotional distress in someone else, crying or happiness, causes me to feel similar emotions.

                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                This explains why certain performance art is particularly impactful. Watching other performances by Stelarc, such as Suspensions, make me feel a similar type of discomfort. Seeing characters cry during a movie makes me sad. But why didn’t experiences such as Marina Abromavic’s Rhythm 0 elicit similar responses out of its participants? Watching her go through physical discomfort in response to cutting her with razor blades didn’t prevent participants from further inflicting physical harm. It seems too simplistic and fatalistic, as inferred by the Stanford Prison experiment, to imply that when people have control, they are more sadistic in their actions. However, it is an interesting element to explore in other uses of – implementing or taking away control to augment empathy.


                `
            }, 

        ]
    },
    {
        name: 'Week 3: Surveillance',
        date: 'November 12, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/Katie_Han.png'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                I have been interested in recommendation algorithms for a while, and their ability to sometimes get it right but more frequently miss the mark. Typically, the recommendations are literal repeats of videos that I have already watched or different iterations of products that I have previously purchased.
                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                Over the past four days, I have been monitoring six of my friends’ browsing histories. Every night, I would filter through all of their daily activity and craft personalized (and silly) recommendations sent via email by a helpful “robot” named H1201837JKSL888. This project was largely inspired by Lauren Lee McCarthy’s <a href="https://lauren-mccarthy.com/SOMEONE">SOMEONE</a>, in which she reimagined a human version of Amazon Alexa by allowing New York gallery goers to watch over four participants over a four-month period.

                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                My friends’ browsing histories were shockingly normal compared to my own habits. It could be attributed to the fact that I asked them to download the extension on Monday, so they were less likely to go down an unhealthy browsing rabbit hole during work or school, or perhaps they were self-conscious knowing that I was likely monitoring their activity. Regardless, I feel as though if I were to implement this in the future, I would love to build a monitoring device for peoples’ activity on their phone as well, where I think we embark on our darkest browsing paths ^_^.

                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                In order to monitor the user’s browsing activity, I made a Chrome extension for each participant to download. Upon downloading, the extension automatically saved the last 50 visits and every website thereafter to an AirTable db. The code can be found <a href="https://github.com/wiseshrimp/surveillance-extension">here</a>.
                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Thank you to all who participated :)
                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext h2',
                text: `
                Reading Response:
                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                After having worked for a few years at numerous virtual reality companies creating experiences and software to build those experiences, I was motivated by the potential of VR. However, I quickly started realizing its limitations, which is why I enjoyed reading the article we were assigned in class, “The Limits of Virtual Reality: Debugging the Empathy Machine” by Ainsley Sutherland, particularly because I participated in an iteration of Machine to be Another at MIT Media Lab a few years ago. 

                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Similar to the experience described in the article, I was blindfolded in the room as another participant was randomly chosen to switch bodies with me. After a few minutes, I was staring at myself sitting across from me as I inhabited the body of a very tall man. In the end, we were guided to stand up and hug each other. Though the entire experience was very surreal, it was limited in scope because were controlled by a guide in our movements and because the entire experience lasted a total of two minutes. It felt less like I was inhabiting the other body rather than seeing myself as an externalized other. Though I had inhabited a man’s body, I didn’t feel like I understood the experience of a man any more thoroughly after participating in the experience. 

                `
            }, 
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                As the article mentioned, virtual reality has been touted as the “ultimate empathy machine” by VR enthusiasts such as Chris Milk, Robert Ebert, and Jaron Lanier. Though Lanier presents the double-edged sword ability relating to its power as an empathy machine, Chris Milk is desperately trying to make content that will change society. However, this touting of the empathy machine isn’t unique to virtual reality, as similar things have been said with every shiny new technology, such as video and even literature. Many VR enthusiasts cite Stanford’s Becoming Homeless study, in which participants inhabited the body of someone who recently lost their job leading them to homelessness and after completing the experience reported having signed a petition in support of Proposition A, which supported increasing affordable housing for vulnerable populations in the San Francisco Bay Area. However, this study lacked a true control condition and similarly, I feel as though a similar outcome could be achieved through a well-done documentary. I think VR is fascinating medium with a lot of potential but simply hailing it as the ultimate empathy machine puts less emphasis on the importance of good content.    
                `
            }, 
        ]
    },
    {
        name: 'Week 4: Monuments',
        date: 'November 21, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/MTA_Adrian.png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: 'The MTA still claims that fare evasion poses a serious financial risk, even though the costs of hiring full-time police officers to guard each subway and bus station and the jail time that some people have to face far exceeds the cumulative $2.75 fees that are at stake. Governor Cuomo proposed adding 500 new police officers to further prevent people from hopping the turnstiles. But police officers have the power to decide whether to fine or arrest an individual for low-level offenses, which allows for both explicit and implicit biases to determine life-changing consequences, especially because the NYPD disproportionately targets black and Latinx people who make up more than 85 percent of fare evasion arrests.                '
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                Although there have already been amazing educational pieces addressing the problematic nature of fare evasion policing, such as the guerrilla Don’t Snitch, Swipe subway PSAs, I wanted to make a monument to the unfortunate individuals who had to suffer unnecessary jail time and physical assault for being unable to afford $2.75. 370 Jay Street was the perfect place to display it, not only because of its convenience, but also because it formerly served as the MTA headquarters and rests above Jay Street Metrotech, the subway station with the highest number of fare evasion arrests in NYC.


                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                In the monument, participants swipe a Metrocard through a card reader, which superimposes names of people who have been recently arrested for fare evasion.

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                Unfortunately, my initial brainstorm of this project fell through, so I didn’t have a long time to work on the fabrication or electronics. I used two wooden blocks to serve as the swiper and used conductive tape on one side of the block and Metrocard to send a signal to my computer whenever they made contact to change the name. In the future, I would like to use a more reliable sensor, such as an LED / phototransistor to detect the Metrocard swiping because the last minute conductive tape ended up bent and broken after a few people used it. I would also love to have it exist without the computer and with an LCD screen to make it look more like the actual thing.

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext h2',
                isHTML: false,
                text: `
                Reading Response
                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                The United States has a long history of censorship of the arts, and NYC is no exception despite its boasting as an accepting metropolitan arts center. After the long string of censorship attempts at the Guggenheim and the Whitney, I was really intrigued by this question of censorship of the arts. I really enjoyed reading Jillian Steinhauer’s article Sam Durant Doesn’t Need Defending and articulated many of my feelings about not only Sam Durant’s but also Dana Schutz’s piece at the Whitney. Both are white artists profiting off the violent imagery of a history that they did not suffer. They have the privilege of aestheticizing an experience that they are far removed from without even consulting the communities that are directly affected. As Steinhauer notes: “If the Walker has set any kind of precedent for museums, it’s not a “difficult” but an obvious one — that they should be in meaningful dialogue with the communities around them, especially marginalized ones.” Unfortunately, people in charge of making decisions at museums and public art projects are very infrequently people of color, which frequently leads to poor decision-making exemplified by Walker Art Center and Mia Locks and Christopher Lew, the curators of the Whitney Biennial 2017.

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                I personally think that Dana Schutz’s Open Casket at the Whitney should have been removed because people who actually owned the painting’s history wanted it removed. However, I think my opinion becomes a little hypocritical when I start to consider my opinion on the Guggenheim’s Art and China after 1989 show in which they decided to censor three works, Huang Yong Ping’s Theater of the World, Xu Bing’s A Case Study of Transference, and Sun Yuan’s Dogs that Cannot Touch Each Other. The incorporation of animals is a large part of contemporary Chinese art and censoring three works out of one of the few Chinese contemporary art exhibits in NYC is censorship of a culture. China’s views on animals perhaps are slightly different from America’s give-me-slaughterhouses-but-please-don’t-show-me culture, but their decision to censor these pieces demonstrates a narrow-minded Western-centric view of art.


                `
            },
        ]
    },
    {
        name: 'Week 5: Final Proposal',
        date: 'November 21, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/alone_together.png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                Having all recently broken up with our long-term live-in partners, the three of us have found singledom to be extremely uncomfortable and sad. At one point or another, we have all resorted to serial dating, drugs, and copious amounts of masturbation. However, all of these coping mechanisms merely distract from the core problem: not knowing how to be alone.  Who are we now without our partners? What do we do with our time? How do you fill the void? Is it happiness if it’s not shared?

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                We want to push ourselves to the most drastic form of loneliness we can imagine – eight hours in a room without any stimulation. We will allow ourselves three five-minute bathroom breaks. The entire experience will be individually recorded and broadcast on the web for people to see. 
                Ideally, the three of us will be close in proximity and experience the solitude in the same time frame. No one is truly alone in their loneliness.  
                Will we improve our ability to be alone after the experience? Can we endure eight solitary hours? What will we do to occupy the time? We only have questions. 
                As much as we don’t want to do this, we hope we can glean something from the experience. We know the power of discomfort, and there’s so much to learn from risk and vulnerability. 
                `
            },
        ]
    },
    {
        name: 'Final: Alone Together',
        date: 'December 15, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/alone_together.png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                Loneliness itself is an uncomfortable feeling, but it really exacerbates depression and anxiety. The surest way to relieve anxiety and depression is to be mindful and present, so Emily, Nicole, and I pushed ourselves to spend six hours in a room without any stimulation. 

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                Initially, we were planning on sitting in isolation for eight hours with little to no structure to heighten the discomfort from isolation that we were collectively feeling. In order to make the experience more interactive, we were going to create a public URL that friends and strangers could visit to view a live stream of each of our rooms and send messages that we could view later. But after reading Nick’s feedback, we realized the necessity of hammering out the goals of the experience. We agreed that we were trying to cope with loneliness rather than heighten its discomfort, which the current structure of the performance was likely not going to achieve. We decided that different mindful practices would help us attain peace with our loneliness. We did away with the live stream aspect of the performance, since we felt that it would insinuate surveillance, which would inevitably alter our behavior and detract from the goals we were trying to accomplish.


                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext h2',
                isHTML: false,
                text: `
                Schedule and Techniques

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: true,
                text: `
                Together, drawing on our own personal experiences, we also included mantra, symbolic rituals,  movement meditation, and self-love exercises<br /><br/ >

                Allowance of 3 Bathroom Breaks<br/>
                Sue: on the hour<br/>
                Nicole: on the :15<br/>
                Emily: on the :30<br/><br/>

                Mantra: I am more than enough. <br/><br/>

                8:00 - 8:30	Settling in: With pen and paper, writing our intentions<br />
                8:30–9:00	Gratitude: People, opportunities, essentials, etc… <br />
                9:00–9:30	Walking meditation<br />
                9:30–10:30	Object/theme: String<br />
                10:30–11:30	Mantra/writing or Bodywork : Massaging, body love<br />
                11:30 - 12:00 	Breathing meditation<br />
                12:00 - 1:00	Debrief
                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: true,
                text: `
                Each room had the following:
                <ul>

                <li>GoPro to record a timelapse at 5 second intervals</li>
                
                <li>Laptop with webcam to record full length video</li>
                
                <li>Chair</li>
                
                <li>Table</li>
                
                <li>Blackout curtain for privacy</li>
                <li>Yarn</li>
                </ul>

                `
            },
            {
                type: DataTypes.Iframe,
                iframe: '<iframe src="https://www.youtube.com/embed/qCMIEoeU7L8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                class: 'iframe'
            },
            {
                type: DataTypes.Text,
                class: 'maintext h2',
                isHTML: false,
                text: `
                Debrief: Final Thoughts and Reactions
                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                We all agreed that the experience was much easier than we anticipated, demonstrating that our fear of loneliness is often worse than the experience of it. It was still difficult, but instead of feeling tortured, we were able to create a positive and peaceful experience. Afterwards, we were all in a state of calm. 
                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                Our breakups prompted us to formulate this experience, but we only felt gratitude for the various people and things that existed in our lives.

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext h2',
                isHTML: false,
                text: `
                Questions
                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: true,
                text: `
                <ul>

                <li>How would the experience be different if we were in relationships and not feeling lonely? </li>

                <li>What if this experience happened immediately after our breakups, without sufficient time to process our emotions?</li>
                
                <li>Would it have been harder if it took place in the morning when we weren’t so exhausted? </li>
                
                <li>How would our behaviors have changed if we incorporated the audience in an interactive way? </li>
                
                <li>How different would it be if the room was soundproofed? (We observed voices of other students and the humming of the laptop fans)</li>
                
                <li>Did the presence of a laptop/camera alter our behavior?</li>
                
                <li>How would the experience change if we were not in a school setting?</li>

                </ul>

                `
            },

        ]
    }
]

const ICMBlogPosts = [
    {
        name: 'Week 1: Pixels',
        date: 'November 6, 2019',
        content: [
            {
                type: DataTypes.Iframe,
                iframe: '<iframe src="https://player.vimeo.com/video/382130014" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
                class: 'iframe'
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    After learning about pixels, videos, and photos, I wanted to manipulate my webcam footage and dumb it down to create the simplest of line drawings. 
                    Using <a href="https://github.com/justadudewhohacks">Vincent Müller’s face-tracking library</a>, I got the coordinates of my jaw, nose, lips, eyes, and eyebrows, which I used to draw curved vertexes of my facial features. 
                    Although it was fun interacting with 2D animation me, I wanted the drawing to be a bit more interactive and incorporate the actual webcam feed. 
                    If you click on one of the facial feature drawings, it zooms into the live footage of that facial feature. I averaged the RGB values of the zoomed-in video feed to change the color of the body background to match the video. 
                    There are also two different modes, draw and see, the only difference being that the background isn’t drawn every time in “see”. This would lead to some fun effects if you matched up “draw” with the facial feature live footage because you could move your face in front of a zoomed-in image of your nose :)
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                Lastly from all of the stress I’ve been feeling from school, work, and change-of-season sickness, I added a “chaos” feature. If you click on any area of the canvas outside of the designated facial features, the drawing goes wild.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    <a href="https://draw-camera.herokuapp.com/">link to sketch</a> (hosted on heroku) and <a href="https://github.com/wiseshrimp/drawing-camera">github</a>
                `
            }
        ]
    },
    {
        name: 'Week 2: Sound',
        date: 'November 20, 2019',
        content: [
            {
                type: DataTypes.Iframe,
                iframe: '<iframe src="https://player.vimeo.com/video/382130546" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
                class: 'iframe'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'subtext',
                text: `
                    Overview
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    I haven’t worked too much with sound on the web, so I was very excited for this project! I’ve been interested in different navigation interfaces and wanted to design a game that didn’t use the standard WASD/arrow keys but instead could be played exclusively using the microphone. Initially, I was planning on using vertical and horizontal movement using amplitude and frequency. However, averaging the frequencies using FFT was extremely finicky because of the wide range of frequencies in a person’s voice, so I decided to focus solely on the up/down interaction.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    This game is inspired by my cat as she uses very uncommon tactics to get the things she wants. And frequently meows to get some tuna.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'subtext',
                text: `
                    Gameplay
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    The purpose of the game is to make my cat, Kiki, eat as many sushis as possible while avoiding certain obstacles that she doesn’t like (e.g. vacuums, the vet, plastic bags). Speaking more loudly or quietly moves her up and down respectively. To make the game easier, you can keep the mouse pressed to keep her in the same vertical position.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    Game: <a href="https://kiki-eats-sushi.herokuapp.com/">Link</a>
                `
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    Code: <a href="https://github.com/wiseshrimp/mic-game">Link</a>
                `
            }
        ]
    },
    {
        name: 'Final Project: Myspace',
        date: 'December 15, 2019',
        content: [
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/myspace1.png',
                class: 'main-img'
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/myspace2.png',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    For a long time, I’ve been wanting to make a 3D collection of people and memories as a different form of journaling using photogrammetry and three.js. I also wanted to incorporate a handwritten element into the website, so I could communicate to my friends and loved ones through little messages on top of their 3D scan.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    For the navigation screen, I used p5 to animate different cutouts of people/objects that had a 3D environment. Once you click on the cutout, it’ll take you to that specific person or object’s ‘room’ with a 3D model with pictures of that person/object as a texture on the walls of the room. I experimented with a few different free photogrammetry softwares: 3DF Zephyr, Scandy, and Autodesk Recap, and I found Recap to produce the best 3D scans from the same photo set. I took about one-hundred photographs from different vantage points circling the object or person, and Recap would generate a .mtl and .obj file corresponding to the object, which I would render in the WebGL canvas. I wanted to make a skybox editor but lacked the time (although I am definitely going to be working on this over winter break!), so I manually made the skybox textures on Illustrator. 
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/myspace_blog.png',
                class: 'left'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    I drew a deconstructed cube and mapped several photos to each side of the cube. I didn’t want the room to be very two-dimensional with a different photo on each wall, but the skybox ended up looking a little weird and distorted with the continuous photos along continuous edges.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Once you clicked on the 3D model, a photo of the message that I wrote about the person/object would fade in. 
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'subtext',
                text: `
                    IMPROVEMENTS
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    I was really happy with the way that most of the 3D models turned out and was pleasantly surprised that they looked good on the web. However, I do want to make many improvements in the future!
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    In my first iteration, I started loading all of the 3D objects, materials, and textures once a user landed on the navigation page, but it led to slight glitching as each object finished loading and muddling of the 3D canvas with several objects showing up in the scene. However, I think these problems could be fixed and are essential to making sure that loading each object doesn’t take as long as it does now! I also think converting the .obj/.mtl file to a JSON file would speed up loading. 
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/myspace3.png',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    <a href="https://github.com/wiseshrimp/livejournal">Github</a><br />
                    <a href="http://sues-space.herokuapp.com/">Link to project</a>
                `
            }
        ]
    }
]

const FabBlogPosts = [
    {
        name: 'Week 1: Flashlight',
        date: 'December 15, 2019',
        content: [
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/flashlight.png',
                class: 'right'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: 'This week, I decided to make a paper lantern as a slight variation to the flashlight. '
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: 'Because I wanted to use the drill skills we learned from class, I decided to make the structure out of wood. '
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `To construct the skeleton of the lamp, I used the bandsaw to cut 20 pieces of scrap wood, 10 shorter ones to accommodate for the extra corner length, and fastened the corners with wood glue and screws.
                I cut crepe paper to fit the windows and fastened them with tape on the inside so they could easily be moved around. Because the corners were weirdly constructed, the side of the papers didn’t have wood that could hold it in place. Lastly, I cut a small top out of scrap wood for the lantern using the bandsaw so the LED interior wasn’t exposed.`
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/flashlight_frame.jpg',
                class: 'left'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `For the lights, I soldered 8 white LEDs in parallel and attached them to a 9V battery holder with a switch.                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'subtext',
                text: `LESSONS LEARNED`
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `Though the final product wasn’t how I wanted it to turn out, it was really nice getting my hands dirty and working with wood again. My main takeaways from this project are:
                `
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    <ul>
                    <li>Plan ahead! Too anxious to get started, I didn’t draw anything out, which would have prevented a lot of hiccups along the way ~lightbulb moment~. I didn’t know how many of the cube modules I wanted. If I had planned the structure out, I could have cut fewer, longer pieces out and not have to worry about fastening so many corners</li>
                    <li>Keep it clean! My final product would have been so much cleaner if I had wiped the wood glue off while it was still wet!</li>
                    <li>Work with electronics concurrently with fabrication! I constructed the wooden enclosure prior to wiring the LEDs, and I think I could have distributed the LEDs way more evenly within the lamp if I had constructed them at the same time – or perhaps with more planning</li></ul>
            
                `
            }
        ]
    },
    {
        name: 'Week 2: Making 5',
        date: 'September 19, 2019',
        content: [
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/making5.jpg',
                class: 'main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                This week, we were tasked to create multiples of a thing using the same process. I was inspired by nature, particularly insects who are the original inventors of the assembly line. 
                In this project, I wanted to make multiples of something that would make a larger whole like a single hexagon that comes together with a thousand others to form a beehive. <br />
                I used a sheet of 6” x 24” 1/4”-thick plywood that I cut into six pieces using the miter saw.<br />
                Drilling the holes was actually one of the more difficult aspects of the project. I didn’t realize how much the wood would split if the holes were that close to the edges of the wood. Initially, I started out with all of the wood pieces taped/clamped together, but that made the splitting worse. I tried different drill bits, but the splitting was unavoidable.<br />
                I definitely felt like I bit off more than I could chew with this project, but I feel like I learned a lot and gained confidence using the machines. Overall, I’m just happy it’s standing!
                `
            },
            {
                type: DataTypes.Image,
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/making5_top.jpg',
                class: 'main-img'
            }
        ]
    },
    {
        name: 'Week 3: Using the Laser Cutter',
        date: 'September 26, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/IMG_2900+(2).png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                    Everyday is Cat Appreciation Day!
                `
            }, 
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/IMG_2900+(1).png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                On April 20, 2017, an angel was born. This week, I decided to build a shrine for her :)
                `
            }, 
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                This week, I wanted to make a box dedicated to the various things I’ve collected of Kiki’s: her baby teeth, shed whiskers, etc. I love the laser cutter because of its ability to cut so precisely, but I wanted to integrate some organic-like elements into the box. Instead of exclusively using harsh lines, I wanted to cut round windows. I etched various images of my cat on pink extruded acrylic and sewed it to the wooden enclosure. Then, I glued the box joints together to hold everything together.
                `
            }, 
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/Screen%2BShot%2B2019-09-26%2Bat%2B11.36.34%2BAM.png'
            },
        ]
    },
    {
        name: 'Week 4: Enclosures',
        date: 'October 3, 2019',
        content: [
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                I’ve been wanting an automated cat feeder for a while. My sister has a Furbo, a treat dispenser controlled by a phone app, but it costs almost $200, so I wanted to make my own! 
                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                I had a container lying around that I modeled in Fusion 360 using the calipers and angle measurer to get precise measurements. The idea was to use a Raspberry Pi, servo, camera, and speaker, so I could watch my cat while she ate the treat and she could hear me speak through the speaker. Unfortunately, I was only able to 3D print the parts this week to house the electronics but didn’t finish the app. Hopefully, I’ll have a chance to work on it further in the following weeks. If I were to actually finish this project, I probably would choose a different container, as the cylindrical surface proved difficult to drill.
                `
            },
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/Screen%2BShot%2B2019-10-06%2Bat%2B11.08.14%2BPM.png'
            },
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/Screen%2BShot%2B2019-10-06%2Bat%2B11.08.33%2BPM.png'
            },
        ]
    },
    {
        name: 'Week 5: Materials',
        date: 'October 9, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/image-asset+(1).jpg'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                I always forget to bring my keys when I leave the apartment. I'm embarrassed to admit how many times I’ve had to climb up my fire escape six floors to my apartment, take out my air conditioner, and climb through the window because I’ve forgotten my keys.  So this week, I made a key holder that I could mount right next to the door so I (hopefully) won’t forget them! 
                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext h2',
                isHTML: false,
                text: `
                Materials:
                `
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/image-asset.jpg'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                I wanted to use magnets, which I had a few extra of lying around my house, so I could place my keychain near the holder and it would snap into place. I wanted to make some boobs because the objects in my living room are very breast themed. I’ve experimented with polymer clay before, but I was worried about baking the clay while the magnets were inside of them. Luckily, I found some Play-Doh in the junk shelf that could air dry!

                `
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                I sculpted the Play-Doh and inserted the magnets into the nipples and let the clay dry overnight. Then, I put a few layers of Gesso all over the piece to make sure that it would hold the paint well. After the Gesso dried, I applied a few layers of acrylic paint and then some gloss varnish to make it shiny.

                `
            },
            {
                type: DataTypes.Image,
                class: 'right',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/gesso_boobs.jpg'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                For the back, I cut a piece of wood that I got at Michael’s a while ago. (I had two other pieces that I decided to laser engrave illustrations of my roommate and me). My roommate’s key set is insane – She has SO MANY KEYS and multiple carabiners… Why do you need MULTIPLE carabiners?! So I installed two hooks at the bottom of the wood in case the magnets weren’t strong enough for her keys. Lastly, I glued the finished boob piece to the wood with Gorilla super glue because both the wood and the clay were porous enough. Hopefully, the bond is strong enough with my roommate’s heavy keys :-0

                `
            },
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/wood_carvings.jpg'
            },
            {
                type: DataTypes.Text,
                class: 'subtext',
                isHTML: false,
                text: `
                Some engravings of me and my roommate that I made with the extra wood :)

                `
            },
        ]
    },
    {
        name: 'Week 6: Mounting Motors',
        date: 'October 17, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'main-img',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/IMG_4643.jpg'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text:
                `
                I love anything related to puppets especially marionettes. For this week’s assignment, I wanted to mechanize a marionette with eight joints (elbows, hands, knees, and feet) that would be attached to continuous servo motors and an Arduino for movement. 
                ` 
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text:
                `
                First, I made the marionette that I would be automating. I fashioned the head and hands out of polymer clay and repurposed a drawing mannequin I already had for the body by replacing the springs with wire. I bought some Ken doll clothing so he wouldn’t be dancing in his birthday suit.
                ` 
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text:
                `
                After finishing the marionette, I started working on the enclosure, which I designed as a cube outline so I could easily attach/detach decorative paper walls to the sides. I bought two eight-foot 2”x2” planks of wood that I chopped into smaller pieces with the chop saw to build a 1’x1’x16” enclosure. I wanted to join the enclosure with dowels so you wouldn’t be able to see screws on the exterior. However, I didn’t know where the dowel jig was, so I tried using a technique in which I would drill a small hole on a piece of wood, place a nail inside of the hole, and hammer the attaching piece of wood to see where the hole aligned. Then, I would remove the nail, drill a pilot hole and then a bigger hole where the dowel would go. Unfortunately, this method was pretty imprecise and the resulting enclosure was pretty crooked.
                ` 
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/image-asset.png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text:
                `
                Once I was done with the enclosure which took longer than expected, I needed to work on mounting the motors so I could start testing the marionette movement. For the mini servos, I 3D modeled and printed eight motor mounts and a spool that would move the joints. The mounts fit the servo okay, but I improperly placed the holes because once the servo was in place, it was nearly impossible to mount the front of it. In retrospect, I should’ve extended the front of the mount so I could screw all of the sides. The spool was an even bigger disaster. In the first prototype, the cutout for the horn was too small, which was fixed by using the Dremel to stretch out the edges. For the rest, I printed them with a bigger horn.
                ` 
            },
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/image-asset+(1).png'
            },
            {
                type: DataTypes.Image,
                class: 'right',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/image-asset+(2).png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text:
                `
                When you said to prototype and buy more materials than you needed, you were definitely right. The night before this was due, I was laser cutting the top of the enclosure, which the motor mounts would screw into, but the laser cutter was a little bit off even though I imported the sketch directly from the Fusion 360 model! Thus, the screw holes on the motor mounts were virtually useless and I ended up attaching the mounts with super glue. I also messed up on the gap between the motor rows, so I had to cut off the wood and attach the motor mounts to a new piece of scrap wood! Set back a few hours, I had a sliver of time to finally test everything out, which is when everything really went wrong. 

                ` 
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text:
                `
                Electronically, all of the power supplies mysteriously disappeared, so I tried powering the motors with my mere 5V Arduino power supply, which it was not happy about. I ended up only managing to get two motors to move with the 5V. 
                ` 
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: true,
                text:
                `
                For the full version, see <a href="https://sueroh.com/work/danceofdespair">Dance of Despair</a>!
                ` 
            },
        ]
    }
]

const PCompBlogPosts = [
    {
        name: 'Week 2: Switches',
        date: 'September 18, 2019',
        content: [
            {
                type: DataTypes.Image,
                class: 'right',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/bra.png'
            },
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                This week, we were tasked to build a switch. 
                I decided to wire a bra that would light up nipples when you hooked it. 
                The circuit itself was very simple. 
                I used 2 3V/20mA LEDs with a 9V battery and 150Ω resistor with soldered connections to keep it secure. 
                Unfortunately, the metal wiring on the bra was coated in paint and thus didn't conduct as well as I would've liked, so the wires had to be touching in order for the circuit to work. 
                I should’ve used the multimeter beforehand to test whether the hooks were conductive prior to building the circuit around the bra. 
                If I were to do this again, I would definitely build my own bra hooks to make sure that the metal was conductive enough!
                `
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe src="https://www.youtube.com/embed/62jVxVBThew" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        ]
    },
    {
        name: 'Week 3: Using the Arduino',
        date: 'September 25, 2019',
        content: [
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                This week, I wanted to play around with Arduino’s tone function using the speakers given to us. I pulled most of the code from Brett Hagman who translated various songs, like the Star Wars theme song, the Simpsons, etc., to values that the Arduino could read. However, I wanted to control the speed at which the song played, so I connected a potentiometer to the Arduino’s analog pin and implemented a basic map function so I could control the bpm of the song at the next loop.
                `
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe src="https://www.youtube.com/embed/leunjXzNoN4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        ]
    },
    {
        name: 'Week 5: Hungry Cat',
        date: 'October 8, 2019',
        content: [
            {
                type: DataTypes.Text,
                class: 'maintext',
                isHTML: false,
                text: `
                Kim is a hungry cat, but she doesn’t like distractions during her meals, so I made a sign warning my roommate or any other passerby to tiptoe. In keeping with my larger project of building an automated cat feeder, I experimented with different ways of determining if my cat was eating. I tried using the FSR, but it was tricky if she wasn’t directly touching the small surface area of the FSR, and I didn’t want to solder it :-0 Then, I tried using buttons, but she wouldn’t eat with her paws on the buttons. I placed a larger surface against two push buttons, so if she rested her weight on the surface (i.e. if she was eating), then it would trigger the servo to move.
                `
            },
            {
                type: DataTypes.Iframe,
                class: 'iframe',
                iframe: '<iframe src="https://www.youtube.com/embed/_VfH9a5jqW0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
            }
        ]
    },
]

export {ClassTitles, ICMBlogPosts, DiscomfortBlogPosts, FabBlogPosts, PCompBlogPosts}