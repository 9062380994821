const DataTypes = {
    Image: 'img',
    Text: 'text',
    Video: 'video',
    Iframe: 'iframe'
}

const MathForArtistsPosts = [
    {
        name: 'Week 1: Counting Visualization',
        date: 'February 5, 2020',
        content: [
            {
                type: DataTypes.Image,
                class: 'left',
                src: 'https://sues-website.s3.us-east-2.amazonaws.com/mfa_fib.jpg'
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    This week, we were assigned to make a visualization of a counting pattern, and I decided to play with the Fibonacci sequence.
                `
            },
            {
                type: DataTypes.Text,
                isHTML: false,
                class: 'maintext',
                text: `
                    Nothing I made in this visualization is especially unique, since the Fibonacci sequence already has beautiful representations in nature and art. 
                    Connecting the corners of the Fibonacci squares into an arc unveils a spiral pattern known as the Fibonacci spiral.
                `
            },
            {
                type: DataTypes.Iframe,
                iframe: '<iframe src="https://player.vimeo.com/video/389165351" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
                class: 'iframe main-img'
            },
            {
                type: DataTypes.Text,
                isHTML: true,
                class: 'maintext',
                text: `
                    <a href="https://fibonacci-spirals.herokuapp.com/">Link</a><br />
                    <a href="https://github.com/wiseshrimp/fibonacci">Code</a>
                `
            },
        ]
    }
]

export default MathForArtistsPosts