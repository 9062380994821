import React from 'react'

import {MenuItems, DataTypes} from './constants/menu'

export default class Blog extends React.Component {
    maximizeImage = ev => {
        document.getElementById('fullscreen-img').src = ev.target.src
        document.getElementById('fullscreen').style.display = 'flex'
        document.getElementById('fullscreen').addEventListener('click', this.minimizeImage)

        document.getElementById('close-fullscreen').addEventListener('click', this.minimizeImage)
    }

    minimizeImage = ev => {
        if (ev.target.id === 'fullscreen-img')
            return
        document.getElementById('fullscreen').style.display = 'none'
        document.getElementById('close-fullscreen').removeEventListener('click', this.minimizeImage)
    }

    renderPost = (piece, idx) => {
       switch (piece.type) {
            case DataTypes.Image:
                return <img 
                    alt="Blog"
                    onClick={this.maximizeImage}
                    key={piece.src}
                    src={piece.src} className={piece.class} />
            case DataTypes.Iframe:
                return (
                    <div 
                        key={`${piece.class}-${idx}`}
                        dangerouslySetInnerHTML={{__html: piece.iframe}} 
                        className={piece.class}></div>
                )
            default: // Default text
                if (piece.isHTML)
                    return <div 
                        key={`${piece.src}-${idx}`}
                        dangerouslySetInnerHTML={{__html: piece.text}} 
                        className={piece.class}></div>
                else
                    return <div 
                        key={`${piece.src}-${idx}`}
                        className={piece.class}>{piece.text}</div>
        }
    }

    renderBlogPost() {
        let {content} = MenuItems['blog'][this.props.class][this.props.post]

        if (!content)
            return null
        else
            return content.map(this.renderPost)
    }

    render() {
        let post = MenuItems['blog'][this.props.class][this.props.post]
        let isOHDays = this.props.class === 'OHDAYS'
        return (
            <div className={`${isOHDays ? 'center' : ''} slide-up-fade-in`}>
                <div className="title">{post.name}</div>
                <div className="subtext center">{post.date}</div>
                {this.renderBlogPost()}
            </div>
        )
    }
}
