import React from 'react'

import {Projects} from './constants/projects'
import {DataTypes} from './constants/menu'

export default class Project extends React.Component {
    maximizeImage = ev => {
        document.getElementById('fullscreen-img').src = ev.target.src
        document.getElementById('fullscreen').style.display = 'flex'
        document.getElementById('fullscreen').addEventListener('click', this.minimizeImage)

        document.getElementById('close-fullscreen').addEventListener('click', this.minimizeImage)
    }

    minimizeImage = ev => {
        if (ev.target.id === 'fullscreen-img')
            return
        document.getElementById('fullscreen').style.display = 'none'
        document.getElementById('close-fullscreen').removeEventListener('click', this.minimizeImage)
    }
    
    renderContent = (piece, idx) => {
        switch (piece.type) {
            case DataTypes.Image:
                return <img 
                        alt="project"
                        key={piece.src}
                        onClick={this.maximizeImage}
                        src={piece.src} 
                        className={piece.class} />
            case DataTypes.Iframe:
                return (
                    <div 
                        key={`${piece.class}-${idx}`}
                        dangerouslySetInnerHTML={{__html: piece.iframe}} 
                        className={piece.class}></div>
                )
            default:
                if (piece.isHTML)
                    return <div 
                        key={`${piece.src}-${idx}`}
                        dangerouslySetInnerHTML={{__html: piece.text}} 
                        className={piece.class}></div>
                else
                    return <div 
                        key={`${piece.src}-${idx}`}
                        className={piece.class}>{piece.text}</div>
        }
    }

    renderProject = () => {
        let {content} = Projects[this.props.project]
        if (!content) 
            return <div></div>
        if (content) {
            return content.map(this.renderContent)
        } 
    }

    render() {
        return (
            <div className="slide-up-fade-in">
                <div className="title">{Projects[this.props.project].name}</div>
                {this.renderProject()}
            </div>
        )
    }
}
