import React from 'react'
import Sketch from 'react-p5'
import { BrowserRouter, Route, Link } from "react-router-dom"

import Clock from './Clock'
import Popup from './Popup'
import {DesktopIcons, MenuItems, SocialIcons} from './constants/menu'
import './App.css'

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDragging: false,
      endClient: [null, null],
      startClient:[null, null],
      popups: [],
      isGlitching: false,
      isFullscreen: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }

  closeWindow = type => {
    let pidx = this.state.popups.findIndex(popup => popup === type)
    let popups = this.state.popups.slice()
    popups.splice(pidx, 1)
    this.setState({
      popups
    })
  }

  onDragStart = ev => { // 
    document.addEventListener('mousemove', this.onDrag)
    document.addEventListener('mouseup', this.onDragEnd)
    this.setState({
      isDragging: true,
      startClient: [ev.clientX, ev.clientY],
      endClient: [ev.clientX, ev.clientY]
    })
  }

  onMouseLeave = ev => {
    ev.persist()
    if (!this.state.isDragging)
      return
    if (ev.clientX > window.innerWidth || ev.clientX < 0 || ev.clientY > window.innerHeight || ev.clientY < 0) {
      this.setState({
        isDragging: false
      })
    }    
  }

  onDrag = ev => {
    if (!this.state.isDragging) return
    this.setState({
      endClient: [ev.clientX, ev.clientY]
    })
  }

  onDragEnd = ev => {
    if (!this.state.isDragging) return
    document.removeEventListener('mousemove', this.onDrag)
    document.removeEventListener('mouseup', this.onDragEnd)

    this.setState({
      isDragging: false,
      endClient: [null, null],
      startClient:[null, null]
    })
  }

  onNavClick = ev => {
    if (ev.target.classList.contains('selected'))
      return
    else
      {
        let el = ev.target
        el.classList.add('selected')
      setTimeout(event => {
        el.classList.remove('selected')
      }, 300)}
  }

  renderIcon = icon => (
    // <Draggable 
    // handle=".desktop-icon"
    // bounds="parent">
    <Link 
      key={icon}
      to={`/${icon.split('-')[0]}`}>
        <div 
          id={icon} 
          key={icon} 
          className="desktop-icon"
          onMouseMove={this.onMouseMove}
          onClick={this.onNavClick}
        ></div>
      </Link>
    // </Draggable>
  )

  setup = (p5, canvasParentRef) => {
    p5.createCanvas(
      window.innerWidth, 
      window.innerHeight)
    .parent(canvasParentRef)
    p5.loadImage(process.env.PUBLIC_URL + '/assets/SueIcon.png', icon => {
      this.sueicon = icon
    })
    p5.loadImage(process.env.PUBLIC_URL + '/assets/cat.png', icon => {
      this.caticon = icon
    })
    p5.loadImage(process.env.PUBLIC_URL + '/assets/monster.png', icon => {
      this.monstericon = icon
    })
  }

  onResize = ev => {
    this.setState({
      hasResized: true
    })
  }

  draw = p5 => {
    if (this.state.hasResized) {
      this.setState({
        hasResized: false
      })
      p5.resizeCanvas(window.innerWidth, window.innerHeight)
    }
    p5.clear()
    if (this.state.isDragging) {
      this.drawDragRect(p5)
    }
    if (this.state.isGlitching) {
      p5.image(this.sueicon, Math.random() * window.innerWidth - this.sueicon.width / 2, window.innerHeight * Math.random() - this.sueicon.height / 2)
      p5.image(this.caticon, Math.random() * window.innerWidth - this.caticon.width / 2, window.innerHeight * Math.random() - this.caticon.height / 2)
      p5.image(this.monstericon, Math.random() * window.innerWidth - this.monstericon.width / 2, window.innerHeight * Math.random() - this.monstericon.height / 2)
    }
  }

  drawDragRect(p5) {
    p5.fill('rgba(130, 78, 125, 0.49)')
    p5.stroke('rgba(130, 78, 125, 0.88)')
    p5.strokeWeight(1)
    let {startClient, endClient} = this.state
    let x = startClient[0]
    let y = startClient[1]
    let width = endClient[0] - startClient[0]
    let height = endClient[1] - startClient[1]
    if (width < 0) {
      x = endClient[0]
      width = Math.abs(width)
    }
    if (height < 0) {
      y = endClient[1]
      height = Math.abs(height)
    }
    
    p5.rect(
      x,
      y,
      width,
      height
    )
  }

  setFullscreen = isFullscreen => {
    this.setState({
      isFullscreen
    })
  }

  glitch = ev => {
    if (this.state.isGlitching)
      return
    this.setState({
      isGlitching: true
    })
    setTimeout(this.unglitch, 2000)
  }
  unglitch = () => {
    this.setState({
      isGlitching: false
    })
  }

  renderPopup = type => (
    <Popup 
      key={type}
      isFullscreen={this.state.isFullscreen}
      setFullscreen={this.setFullscreen}
      closeWindow={this.closeWindow}
      type={type} />
  )

  renderSubRoute = (route, idx) => (
    <Route exact path={route}
      key={`${route}-${idx}`}
      render={props => <Popup 
        {...props}
      setFullscreen={this.setFullscreen} 
      isFullscreen={this.state.isFullscreen} />}
    />
  )

  renderRoutes = () => {
    let workRoutes = []
    let blogRoutes = []
    for (let type in MenuItems) {
      for (let subtype in MenuItems[type]) {
        if (type === 'blog') {
          blogRoutes.push(`/blog/${subtype.toLowerCase()}`)
        }
        for (let post in MenuItems[type][subtype]) {
          if (type === 'work')
            workRoutes.push(`/${type.toLowerCase()}/${post.toLowerCase()}`)
          else if (type === 'blog') 
            blogRoutes.push(`/${type.toLowerCase()}/${subtype.toLowerCase()}/${post.toLowerCase()}`)
          
        }
      }
    }

    return (
      <div>
        {workRoutes.map(this.renderSubRoute)}
        {blogRoutes.map(this.renderSubRoute)}
        <Route exact path="/work" 
          key='work'
          render={props => <Popup 
            {...props}
          setFullscreen={this.setFullscreen} 
          isFullscreen={this.state.isFullscreen} />}
         />
        <Route exact path="/work/virtual" 
          key='work-virtual'
          render={props => <Popup 
            {...props}

          setFullscreen={this.setFullscreen} 
          isFullscreen={this.state.isFullscreen} />} />
        <Route exact path="/work/physical" 
          key='work-physical'
          render={props => <Popup 
            {...props}

          setFullscreen={this.setFullscreen} 
          isFullscreen={this.state.isFullscreen} />} />
        <Route exact path="/about" 
          key='about'
          render={props => <Popup 
            {...props}

          setFullscreen={this.setFullscreen} 
          isFullscreen={this.state.isFullscreen} />} />
        <Route exact path="/blog" 
          key='blog'
          render={props => <Popup 
            {...props}

          setFullscreen={this.setFullscreen} 
          isFullscreen={this.state.isFullscreen} />} />
      </div>
    )
  }

  renderSocialIcon = icon => (
    <a
      key={icon.class} 
      href={icon.link} 
      rel="noopener noreferrer" 
      target="_blank">
      <div className={`social-icon ${icon.class}`}></div>
    </a>
  )

  renderSocialIcons = () => (
    <div className="social-icons-container">
      {SocialIcons.map(this.renderSocialIcon)}
    </div>
  )

  render() {
    return (
      <BrowserRouter>
      <div>
        <div id="fullscreen" className="fade-in">
          <img
            alt="fullscreen of project"
            id="fullscreen-img" 
            src="" />
          <div id="close-fullscreen">&#10005;</div>
        </div>
        <Sketch setup={this.setup} draw={this.draw} />
        <div className="os-container">
          <div className="background"
            onMouseDown={this.onDragStart}
            onMouseLeave={this.onMouseLeave}
          ></div>
          <div className="desktop-container">
            {DesktopIcons.map(this.renderIcon)}
            {this.renderRoutes()}
            {/* {this.state.popups.map(this.renderPopup)} */}
            <div className="bar-container">
              <div 
                onClick={this.glitch}
                className="nav-button" 
                id="start-button"></div>
              {this.renderSocialIcons()}
              <div className="nav-button" id="internet-expl-nav">
                Internet Explorer
              </div>
              <div className="right-nav">
                <Clock />
              </div>
            </div>
          </div>
        </div>
      </div>
      </BrowserRouter>
    
    )
  }
}

export default Desktop
