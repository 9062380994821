import React from 'react'
import Draggable from 'react-draggable'
import {Link} from 'react-router-dom'

import About from './About'
import Project from './Project'
import Blog from './Blog'
import {MenuItems} from './constants/menu'
import {Projects} from './constants/projects'


import {ClassTitles} from './constants/blog'
import './Popup.css'


export default class Popup extends React.Component {
    constructor(props) {
        super(props)

        let {
            menuSelectedItem,
            selectedSubNav,
            type
        } = this.parseRoutes(props)

        this.state = {
            type,
            menuSelectedItem,
            selectedSubNav,
            isFullscreen: props.isFullscreen
        }

        this.windowRef = React.createRef()
        this.subWindowRef = React.createRef()
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            Array.from(document.getElementsByClassName('caption')).forEach(caption => {
                caption.classList.add('mobile')
            })
        }
    }

    componentDidUpdate() {
        let {menuSelectedItem, selectedSubNav} = this.parseRoutes(this.props)

        if (
            (!this.state.menuSelectedItem && menuSelectedItem) || 
            (!this.state.selectedSubNav && selectedSubNav))
            this.setState({
                menuSelectedItem,
                selectedSubNav
            })

    }

    expandWindow = ev => {
        let isMinimized = this.state.isFullscreen
        this.props.setFullscreen(!isMinimized)
        this.windowRef.current.style.width = isMinimized ? '80vw' : 'width: calc(100% - 4px);'
        this.windowRef.current.style.transform = 'none'
        this.windowRef.current.style.top = '0'
        this.windowRef.current.style.left = '0'
        this.windowRef.current.style.height = isMinimized ? '70vh' : 'calc(100% - 40px)'
        this.subWindowRef.current.style.height = isMinimized ? '70vh' : 'calc(100% - 40px)'
        this.setState({
            isFullscreen: !isMinimized
        })
    }

    parseRoutes = props => {
        let routeArr = props.match.path.split('/')
        let type = routeArr[1]
        let menuSelectedItem, selectedSubNav
        let isWork = type === 'work'

        if (isWork && routeArr.length > 2) {
            if (
                routeArr[2] === 'virtual' ||
                routeArr[2] === 'physical'
            ) {
                menuSelectedItem = routeArr[2]
            }
            else {
                for (let subroute in MenuItems['work']) {
                    for (let proj in MenuItems['work'][subroute]) {
                        if (proj.toLowerCase() === routeArr[2]) {
                            menuSelectedItem = subroute
                            selectedSubNav = proj
                        }
                    }
                }
            }
        } else if (type === 'blog') {
            if (routeArr.length > 3) {
                for (let subclass in MenuItems['blog']) {
                    if (subclass.toLowerCase() === routeArr[2]) {
                        for (let post in MenuItems['blog'][subclass]) {
                            if (post.toLowerCase() === routeArr[3]) {
                                menuSelectedItem = subclass
                                selectedSubNav = post
                            }
                        }
                    }
                }
            } else if (routeArr.length === 3) {
                menuSelectedItem = routeArr[2].toUpperCase()
            }
        }
        return {type, menuSelectedItem, selectedSubNav}
    }

    renderName = blog => (
        <div 
            key={blog.blog.name}
            className="blog-nav">
            <Link to={`/blog/${blog.code.toLowerCase()}/${blog.key.toLowerCase()}`}>
                {this.state.menuSelectedItem === '100DAYS' ? <img alt="100 days thumbnail" src={blog.img} /> : null}
                <div 
                    data-class={blog.code}
                    data-key={blog.key}
                    className="blog-title">{blog.blog.name}</div>
                <div className="blog-sub">{blog.class}</div>
            </Link>
        </div>
    )
    

    renderNames = () => {
        let blogItems = []
        if (this.state.menuSelectedItem) {
            for (var blog in MenuItems.blog[this.state.menuSelectedItem]) {
                let item = MenuItems.blog[this.state.menuSelectedItem][blog]
                let img
                let is100Days = this.state.menuSelectedItem === '100DAYS'
                if (is100Days) {
                    for (let idx = 0; idx < item.content.length; idx++) {
                        let blogItem = item.content[idx]
                        if (blogItem.type === 'img') {
                            img = blogItem.src
                            break
                        }
                    }
                }
                blogItems.push({
                    code: this.state.menuSelectedItem,
                    key: blog,
                    blog: item,
                    class: ClassTitles[blog],
                    img
                })
            }
        } else {
            for (var classes in MenuItems.blog) {
                for (var blogPost in MenuItems.blog[classes]) {
                    blogItems.push({
                        code: classes,
                        key: blogPost,
                        blog: MenuItems.blog[classes][blogPost],
                        class: ClassTitles[classes]
                    })
                }
            }
        }
        return (
            <div className="blog-name-c">
                {blogItems.map(this.renderName)}
            </div>
        )
    }

    onWorkNavClick = ev => {
        let link = `/work/${ev.target.dataset.type}`
        if (ev.target.dataset.type === this.state.menuSelectedItem) {
            link = '/work'
        }
        this.props.history.push(link)
    }

    renderNavItem = menuItem => {
        let nav = menuItem
        if (this.state.type === 'blog') {
            nav = ClassTitles[menuItem]
            return (
                <Link 
                    key={menuItem}
                    to={`/blog/${menuItem.toLowerCase()}`}>
                    <div key={menuItem} 
                        data-type={menuItem}
                        className={`noselect nav-item ${this.state.menuSelectedItem === menuItem ? 'selected' : ''}`}>
                        {nav}
                    </div>
                </Link>
            )
        }
        return (
            // <Link 
            //     key={menuItem} 
            //     to={`/work/${menuItem.toLowerCase()}`}>
                <div 
                    onClick={this.onWorkNavClick}
                    key={menuItem} 
                    data-type={menuItem}
                    className={`noselect nav-item ${this.state.menuSelectedItem === menuItem ? 'selected' : ''}`}>
                    {nav}
                </div>
            // </Link>
        )
    }

    renderSubNav = () => {
        if (!this.state.menuSelectedItem) return
        return (
            <div className="fade-in sub-nav-container">
                {Object.keys(MenuItems[this.state.type][this.state.menuSelectedItem]).map(this.renderSubNavItem)}
            </div>
        )
    }

    renderSubNavItem = navItem => {
        let route = this.state.type === 'work' ? `/${this.state.type}/${navItem.toLowerCase()}` : `/blog/${this.state.menuSelectedItem.toLowerCase()}/${navItem.toLowerCase()}`
        return (
            <Link 
                key={route}
                to={route}>
            <div
                key={navItem} 
                data-subnav={navItem}
                className={`noselect sub-nav-item ${this.state.selectedSubNav === navItem ? 'selected' : ''}`}>{MenuItems[this.state.type][this.state.menuSelectedItem][navItem].name}</div>
            </Link>
        )
    }
    

    renderThumbnail = (item, idx) => (
        <div 
                key={item.key}
                className="thumbnail-con">

            <Link to={`/work/${item.key.toLowerCase()}`}>
                <div className="thumbnails"
                    data-subnav={item.key}
                    style={{
                        backgroundImage: `url(${item.thumbnail})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}
                ></div>
                    </Link>
                <div className="caption">{Projects[item.key].name}</div>
            </div>
    )

    renderThumbnails() {
        let navObj = MenuItems[this.state.type]
        let thumbnailsArr = []
        if (this.state.menuSelectedItem) {
            for (let subnav in navObj[this.state.menuSelectedItem]) {
                let {thumbnail, name} = navObj[this.state.menuSelectedItem][subnav]
                let item = {
                    thumbnail,
                    name,
                    key: subnav
                }

                thumbnailsArr.push(item)
            }
        } else {
            for (let nav in navObj) {
                for (let subnav in navObj[nav]) {
                    let {thumbnail, name} = navObj[nav][subnav]
                    let item = {
                        thumbnail,
                        name,
                        key: subnav
                    }
    
                    thumbnailsArr.push(item)
                }
            }
        }
        return (
                
            <div className="thumbnails-container">
                <div className="title">{this.state.menuSelectedItem ? this.state.menuSelectedItem : 'Projects'}</div>
                {thumbnailsArr.map(this.renderThumbnail)}
            </div>
        )
    }

    renderTopNav = () => {
        if (this.state.type === 'about') return
        return (
            <div className="top-browser-container">
                {Object.keys(MenuItems[this.state.type]).map(this.renderNavItem)}
            </div>
        )
    }

    renderWindowNav = () => {
        if (this.state.selectedSubNav) {
            if (this.state.type === 'work') { // Render specific project
                return <Project 
                    project={this.state.selectedSubNav} />
            } else if (this.state.type === 'blog') {
                return <Blog 
                    post={this.state.selectedSubNav} 
                    class={this.state.menuSelectedItem} />
            }
        } else {
            if (!this.state.type === 'about')
                return
            return (
                <div>
                    {this.state.type === 'work' ? this.renderThumbnails() : this.renderNames()}
                </div>
            )
        }
    }

    renderNav = () => (
        <div>
            {this.renderTopNav()}
            {this.renderSubNav()}
        </div>
    )


    render() {
        let fullscreenStyle = {}
        if (this.state.isFullscreen) {
            fullscreenStyle = {
                width: 'calc(100% - 4px)',
                top: '0',
                left: '0',
                height: 'calc(100% - 40px',
                transform: 'none'
            }
        }
        return (
            <Draggable 
                handle=".top-bar"
                cancel=".noselect"
                bounds="html">
                <div 
                    ref={this.windowRef}
                    style={fullscreenStyle}
                    className="window popup" >
                    <div className="top-bar">
                    <Link to="/" className="noselect" onClick={this.onWindowClose}>
                        <div 
                            id="close-button"
                            className="noselect"
                            >&#10005;</div>

                    </Link>
                    <div 
                            id="expand-button" className="noselect"
                            onClick={this.expandWindow} onTouchStart={this.expandWindow}>
                                <div id="expand" />
                            </div>
                    </div>
                    {this.state.type === 'about' ? '' : this.renderNav()}
                    <div 
                        ref={this.subWindowRef}
                        className={`subwindow ${this.state.menuSelectedItem === '100DAYS' ? 'center' : ''}`}>
                            {this.state.type === 'about' ? <About /> : ''}
                    {this.state.type === 'about' ? '' : this.renderWindowNav()}
                    </div>
                    
                </div>
            </Draggable>
        )
    }
}
